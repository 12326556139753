import React from "react";
import "./Footer.css";

import { Link } from "react-router-dom";

export const FooterComponent = () => {
  return (
    <div className="footer-widgets">
      <div className="widget-area footer-widgets-1 footer-widget-area">
        <section id="text-3" className="widget widget_text">
          <div className="widget-wrap">
            <h3 className="widgettitle widget-title">ADSS GLOBAL</h3>
            <div className="textwidget">
              <p>
              
                <br />
                224 Cairnsmore Circle
                <br />
                Ottawa ON K2J 0G5
                <br />
                613-221-5950
              </p>
            </div>
          </div>
        </section>
      </div>
      <div className="widget-area footer-widgets-2 footer-widget-area">
        <section id="nav_menu-2" className="widget widget_nav_menu">
          <div className="widget-wrap">
            <h3 className="widgettitle widget-title">Popular Links</h3>
            <div className="menu-footer-menu-container">
              <ul id="menu-footer-menu" className="menu">
                <li
                  id="menu-item-1446"
                  className="menu-item menu-item-type-post_type menu-item-object-page menu-item-1446"
                >
                  <Link >
                   <Link to="software/sage300"  onClick={() => window.scrollTo(0, 0)}>
                   Sage 300
                   </Link> 
                   
                  </Link>
                </li>
                <li
                  id="menu-item-1450"
                  className="menu-item menu-item-type-post_type menu-item-object-page menu-item-1450"
                >
                  
                    
                  <Link to="software/sage100"  onClick={() => window.scrollTo(0, 0)}>
                  Sage 100
                  </Link>
                 
                </li>
                <li
                  id="menu-item-1456"
                  className="menu-item menu-item-type-post_type menu-item-object-page menu-item-1456"
                >
                 <Link to="software/sage-hrms-payroll"  onClick={() => window.scrollTo(0, 0)}>
                 Sage HRMS &amp; Payroll
                 </Link>
                   
                
                </li>
                <li
                  id="menu-item-1455"
                  className="menu-item menu-item-type-post_type menu-item-object-page menu-item-1455"
                >
                  <Link to="software/sage-crm"  onClick={() => window.scrollTo(0, 0)}>
                    Sage CRM
                  </Link>
                </li>
                <li
                  id="menu-item-2459"
                  className="menu-item menu-item-type-post_type menu-item-object-page menu-item-2459"
                >
                  <Link to="software/sage-intact"  onClick={() => window.scrollTo(0, 0)}>
                    Sage Intact
                  </Link>
                </li>
                <li
                  id="menu-item-3061"
                  className="menu-item menu-item-type-post_type menu-item-object-page menu-item-3061"
                >
                  <Link to="/services/hosting/"  onClick={() => window.scrollTo(0, 0)}>
                    Sage Hosting
                  </Link>
                </li>
                <li
                  id="menu-item-1448"
                  className="menu-item menu-item-type-post_type menu-item-object-page menu-item-1448"
                >
                  <Link to="/services/managed-services/"  onClick={() => window.scrollTo(0, 0)}>
                    Managed Services
                  </Link>
                </li>
                <li
                  id="menu-item-1751"
                  className="menu-item menu-item-type-post_type menu-item-object-page menu-item-1751"
                >
                  <Link to="/services/disaster-recovery/"  onClick={() => window.scrollTo(0, 0)}>
                    Disaster Recovery
                  </Link>
                </li>
                <li
                  id="menu-item-1894"
                  className="menu-item menu-item-type-post_type menu-item-object-page menu-item-1894"
                >
                  <Link to="/services/client-care/"  onClick={() => window.scrollTo(0, 0)}>
                    Client Care Services
                  </Link>
                </li>
                <li
                  id="menu-item-1895"
                  className="menu-item menu-item-type-post_type menu-item-object-page menu-item-1895"
                >
                  <Link to="/services/network/"  onClick={() => window.scrollTo(0, 0)}>
                    Network Solutions
                  </Link>
                </li>
                <li
                  id="menu-item-1896"
                  className="menu-item menu-item-type-post_type menu-item-object-page menu-item-1896"
                >
                  <Link to="https://accpacsupport.bomgarcloud.com/"  onClick={() => window.scrollTo(0, 0)}>
                    Customer Support
                  </Link>
                </li>
                <li
                  id="menu-item-2199"
                  className="menu-item menu-item-type-post_type menu-item-object-page menu-item-2199"
                >
                  <Link to="/support/training-videos/" onClick={() => window.scrollTo(0, 0)}>
                    Sage Training Videos
                  </Link>
                </li>
                <li
                  id="menu-item-2052"
                  className="menu-item menu-item-type-post_type menu-item-object-page menu-item-2052"
                >
                  <Link to="/contact"  onClick={() => window.scrollTo(0, 0)}>
                    Contact Us
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </section>
      </div>
      <div className="widget-area footer-widgets-3 footer-widget-area">
        <section id="media_image-6" className="widget widget_media_image">
          <div className="widget-wrap">
            <img
              width="400"
              height="113"
              src="https://673dc7.a2cdn1.secureserver.net/wp-content/uploads/Sage-diamond-partner-badge.jpg?time=1681363273"
              className="image wp-image-3021  attachment-full size-full"
              alt="Sage Diamond Partner Logo"
              decoding="async"
              loading="lazy"
              style={{ maxWidth: "100%", height: "auto", margin: 10 }}
              srcset="https://673dc7.a2cdn1.secureserver.net/wp-content/uploads/Sage-diamond-partner-badge.jpg 450w, https://673dc7.a2cdn1.secureserver.net/wp-content/uploads/Sage-diamond-partner-badge-300x75.jpg 300w"
              sizes="(max-width: 450px) 100vw, 450px"
            />
          </div>
        </section>
        <section id="media_image-5" className="widget widget_media_image">
          <div className="widget-wrap">
            <img
              width="300"
              height="157"
              src="https://673dc7.a2cdn1.secureserver.net/wp-content/uploads/Sage-cloud-service-badge.jpg?time=1681363273"
              className="image wp-image-3022  attachment-full size-full"
              alt="Sage Cloud Service Provider Badge"
              decoding="async"
              loading="lazy"
              style={{ maxWidth: "100%", height: "auto", margin: "10px" }}
              srcset="https://673dc7.a2cdn1.secureserver.net/wp-content/uploads/Sage-cloud-service-badge.jpg 450w, https://673dc7.a2cdn1.secureserver.net/wp-content/uploads/Sage-cloud-service-badge-300x105.jpg 300w"
              sizes="(max-width: 450px) 100vw, 450px"
            />
          </div>
        </section>
        <section id="text-4" className="widget widget_text">
          <div className="widget-wrap">
            {" "}
            <div className="textwidget">
              <hr />

            </div>
          </div>
        </section>
        <section id="enews-ext-1" className="widget enews-widget" />
      </div>
    </div>
  );
};
