import { Button, Col, Image, Row } from 'antd';
import React from 'react'
import { Link } from 'react-router-dom';
import "../styles/Sage300.css";
import "../styles/ManagedServices.css";
import RightSection from './RightSection';

export const ManagedServices = () => {
  return (
    <div>
       <Row className="sage300-page-1 hero-div overlay" role="banner">
        <div className="sage-custom-header">
          <img
            src={"https://673dc7.a2cdn1.secureserver.net/wp-content/uploads/2017/12/server-bg.jpg"}
            width="1920"
            height="1080"
            alt=""
          />
        </div>
        <div>
            <Col xs={24} sm={12} className="sage-center">
            <h1>Managed Services</h1>
            <h2>Budget Your IT Support Expenses on a Fixed, Monthly Basis</h2>
            </Col>
          </div>
        </Row>
        <div className='sage-div'>
          <div className='sage-left'>
          <h1>Managed IT Services Address Your Critical Business Needs</h1>
          <p>
          At ADSS Global we can enhance your IT experience with proactive services in addition to our technology support offering.
          </p>
          <p>
          Predicting what may fail, and when, is a challenge in maintaining a <Link to="/services/network/"> computer network</Link>. Managing a network by only reacting to issues can lead to lost time. At ADSS Global we have a better way to serve you.
          </p>
          <p>
          We can help keep your key business tools, such as email and internet, up and running. With our Managed Services Program, our technicians look after your network to manage issues BEFORE they affect your productivity.
          </p>
          <h2>Managed Services</h2>
          <div className='sage-left-img'>
            <Image src="https://673dc7.a2cdn1.secureserver.net/wp-content/uploads/managed-services-grid.png" />
           </div>

          <h2>Additional Service Modules</h2>
          <p>
          In addition to services provided under our Managed Services Program, we can augment your solution with any or all of the following additional service modules:
          </p>
          <div>
            <h3 className='services-list-heading'>Desktop Monitoring and Maintenance Services</h3>
            <p>
            <p>
          At ADSS Global, we bring the benefits of the Managed Services Program down to the desktop level with the addition of the Desktop Monitoring and Maintenance module.
          </p>
            </p>
            <ul>
              <li>Monitor for hard drive capacity, performance and CPU workload.</li>
              <li>Ensure your antivirus program is functioning properly and updates are being applied to each desktop.</li>
              <li>Ensure each desktop is being updated with the latest security patches.</li>
              <li>Filter corporate email to reduce spam and potentially harmful viruses or trojans that can destroy your network.</li>
              <li>Assist in designing and enforcing application compliance</li>
              <li>Assist with end-user questions and requests during our monthly on-site visit.</li>
              <li>Assist with small projects, such as moving, adding, or changing desktops or users.</li>
            </ul>
            <h3 className='services-list-heading'>Network Printing Services</h3>
             <p>
             We can monitor printer, toner and paper levels with the addition of Network Print Device Module. Primetime can view the exact error messages from your network printers’ display and quickly determine the best course of action to resolve them.
             </p>
             <h3 className='services-list-heading'>Virtual CIO Assistance for your IT Infrastructure Planning Needs</h3>
             <p>
             With this service we assist you with policy development; budget planning to determine future IT expenditures; strategic technology planning and ongoing input on business impact of IT.
             </p><h3 className='services-list-heading'>Application Sourcing, Deployment and Licensing Renewal</h3>
             <p>
             We can take the hassle out of the sourcing and renewal of key applications, such as antivirus protection. We take care of it, so you don’t have to.
             </p>
          </div>
           <div>
            <h2>Security Services</h2>
              <p>
              Protect your vital organizational data from corruption or theft, and ensure it can survive through disasters or other threats to its integrity.
              </p>
              <h2>Benefits of Managed Services</h2>
              <p><b>Give you peace of mind.</b>Let us be your network watchdog, enabling you to focus on your core business activities instead of putting out fires when network disruptions occur.</p>
              <p><b>Enable continued business productivity.</b>We proactively detect and resolve issues BEFORE they occur through scheduled preventive maintenance designed to keep your network operating efficiently.</p>
              <p><b>Safeguard your network’s integrity.</b>We provide regular checkups to guard operating system patches, antivirus updates and reduce the likelihood that your network will be compromised by a virus, worm, hacker or other malicious attack.</p>
              <p><b>Stabilize your IT spending.</b>We will remove ‘emergency service call’ from your vocabulary and help to create more predictable IT spending patterns.</p>
              <p><b>Access a wealth of IT expertise, tools and experience.</b>Our staff has over 100 years of combined experience and is accredited by IBM, Hewlett-Packard, Cisco, Microsoft, Symantec, Novell and others. We use industry-leading management tools to provide you with the highest level of service possible.</p>
           </div>
          </div>
          {/* Right Section  */}
          <div className='sage-right'>
          <img src="https://673dc7.a2cdn1.secureserver.net/wp-content/uploads/2017/12/server-room.jpg?time=1682639662"  alt='Sage300'/>
           <div><RightSection /></div>
           </div>
        </div>
        <div className='sage-last-section'>
        <Row className="sage300-page-1 hero-div overlay" role="banner">
        <div className="sage-custom-header">
          <img
            src={"https://673dc7.a2cdn1.secureserver.net/wp-content/uploads/2017/12/server-bg.jpg"}
            width="1920"
            height="1080"
            alt=""
          />
        </div>
        <div>
        <Col xs={24} sm={12} className="help-text">
            <h1>How Can We Help?</h1>
            <h3>224 Cairnsmore Circle</h3>
            <h3>Ottawa ON K2J 0G5</h3>
            <h3>613-221-5950</h3>
            <h3> <a href="http://adsshelp.com/"> <em>Or click below to leave a message and one of our consultants will follow up.</em>
           <br/> <Button className="help-btn"   type={"primary"} onClick={(e)=>{
            
           }}>Get In Touch</Button><br/>
            </a></h3>
            </Col>
          </div>
        </Row>
        </div>
      </div>
   
  )
  }