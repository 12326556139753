import { Button, Col, Row } from 'antd';
import React from 'react'


import "../styles/Sage300.css";
import "../styles/Hosting.css";

export const Hosting = () => {
  return (
    <div>
       <Row className="sage300-page-1 hero-div overlay" role="banner">
        <div className="sage-custom-header">
          <img
            src={"https://673dc7.a2cdn1.secureserver.net/wp-content/uploads/2017/12/server-bg.jpg"}
            alt="Hosting" />
        </div>
        <div>
            <Col xs={24} sm={12} className="sage-center">
            <h1>Sage Cloud Hosting</h1>
            <h2>Low, Fixed Monthly Cost - No Additional Bandwidth Needed</h2>
            </Col>
          </div>
        </Row>
        <div className='sage-div'>
          <div className='sage-left'>
          <h1>Managed Hosting Services for Sage</h1>
          <p>
          One of a select few hosting providers officially approved by Sage, ADSS Global offers managed hosting services that lowers the total cost of ownership and eliminates worries over software upgrades or hardware maintenance. Plus, our hosting program removes unnecessary upfront and ongoing capital expenditures.
          </p>
           <div>
           
           </div>
          </div>
          {/* Right Section  */}
          <div className='hosting-right'>
          <img src={"https://673dc7.a2cdn1.secureserver.net/wp-content/uploads/Sage-cloud-service-badge.jpg"}  alt='Sage-cloud-service-badge'/>
         </div>
        </div>
          {/* middle section */}
       <div className='hosting-middle-section'>
         <div className='hosting-card-1'>
          <Row>
          </Row>
         </div>
       </div>
       <div className='hosting-detail'>
         <Row className='hosting-detail-text'>
          <Col>
        <h1>WORRY-FREE HOSTING WITH 99.9% UPTIME</h1>
        <p>
        High-speed bandwidth and internet security are basic parts of the ADSS Global hosting services. Feel safe knowing your critical data are stored in a world-class data center. Never worry about creating, storing, cataloging, or accessing back up disks or tapes and there’s no need for software installations or maintenance so you can free up your IT groups and resources for other projects.
        </p>
        <p>
        You can easily access your software from any computer at anytime which is perfect for remote users and traveling employees. You can also scale up or scale down on a user by user basis without a large capital outlay or wasting resources.
        </p>
        <p>
        With 99.9% uptime guaranteed, you may never need to contact us — but if you do, we stand ready to provide support.
        </p>
        <p>
        See Also: Disaster Recovery
        </p>
        </Col>
         </Row>
         <Row className='hosting-detail-img'>
          <Col>
           <img src={"https://673dc7.a2cdn1.secureserver.net/wp-content/uploads/sage-hosting-server.png"}  alt="hosting-detail-img" />
           </Col>
         </Row>
       </div>

        <div className='sage-last-section'>
        <Row className="sage300-page-1 hero-div overlay" role="banner">
        <div className="sage-custom-header">
          <img
            src={"https://673dc7.a2cdn1.secureserver.net/wp-content/themes/studio-pro/assets/images/hero.jpg"}
            width="1920"
            height="1080"
            alt=""
          />
        </div>
        <div>
        <Col xs={24} sm={12} className="help-text">
            <h1>How Can We Help?</h1>
            <h3>224 Cairnsmore Circle</h3>
            <h3>Ottawa ON K2J 0G5</h3>
            <h3>613-221-5950</h3>
            <h3> <a href="http://adsshelp.com/"> <em>Or click below to leave a message and one of our consultants will follow up.</em>
           <br/> <Button className="help-btn"   type={"primary"} onClick={(e)=>{
            
           }}>Get In Touch</Button><br/>
            </a></h3>
            </Col>
          </div>
        </Row>
        </div>
      </div>
   
  )
  }