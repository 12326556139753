import { Button, Col, Divider, Row } from 'antd';
import React from 'react'
import "../styles/Sage300.css";
import "../styles/office.css";
import { Link } from 'react-router-dom';
export const Office = () => {
  return (
    <div> 
      <Row className="sage300-page-1 hero-div overlay" role="banner">
        <div className="sage-custom-header">
          <img
            src={"https://673dc7.a2cdn1.secureserver.net/wp-content/uploads/2017/12/shaking-hands.jpg"}
            width="1920"
            height="1080"
            alt=""
          />
        </div>
        <div>
            <Col xs={24} sm={12} className="sage-center">
            <h1>ADSS Global Offices</h1>
            <h2>Call Us At: 613-221-5950</h2>
            </Col>
          </div>
        </Row>


        {/* Middle Section of Office */}

        <div className='office-grand-parent-div'>
            <div className='office-parent'>
                 <p>Founded in 1981, ADSS Global is one of the largest, most comprehensive and most experienced <Link to={"#"}> Sage Business Partners</Link> in the world. ADSS Global services over 3,750 companies and organizations in more than 20 countries providing comprehensive global sales and support.</p>
                 <div className='office-child-one-images'>
                 <div>
                  <Link className="zoom-effect-container">
                  <img className='images-transition' src={"https://673dc7.a2cdn1.secureserver.net/wp-content/uploads/adss-exton.png"} alt='Northeast Headquarters:Exton, PA' /></Link>
                  <figure className='figure-1'>Northeast Headquarters:</figure>
                  <figure><strong className='figure-2'>Exton, PA</strong></figure>
                 </div> 
                 
                 <br />
                 <div><Link className="zoom-effect-container" >
                 <img className='images-transition' src={"https://673dc7.a2cdn1.secureserver.net/wp-content/uploads/2018/01/miami.jpg"} alt='Southeast Headquarters:Miami, FL' /></Link>
                 <figure className='figure-1'>Southeast Headquarters;</figure>
                  <figure><strong className='figure-2'>Miami, FL</strong></figure>
                 </div><br />
                 <div><Link className="zoom-effect-container" >
                 <img className='images-transition' src={"https://673dc7.a2cdn1.secureserver.net/wp-content/uploads/2018/01/canada.png"} alt='Canada Headquarters:Toronto, ON' /></Link>
                 <figure className='figure-1'>Canada Headquarters:</figure>
                  <figure><strong className='figure-2'>Toronto, ON</strong></figure>
                 </div><br />
            </div>
            <Divider style={{ border: '2px solid white', marginBottom:'10%', boxShadow: '2px 5px 2px 0px black', height: '' }} />
            
            <h1>Global and Local Offices</h1>
            <Divider />
            <div className='Global-and-Local-Offices'>
        <div className='address-one'>
        <ul aria-label='Northeastern United States'>
        <li><Link>Philadelphia, PA</Link></li>
        <li><Link>Lancaster, PA</Link></li>
        <li><Link>Erie, PA</Link></li>
        <li><Link>Boston, MA</Link></li>
        <li><Link>Syracuse, NY</Link></li>
        <li><Link>New York, NY</Link></li>
        <li><Link>Long Island, NY</Link></li>
        <li><Link>Union, NJ</Link></li>
        <li><Link>Edison, NJ</Link></li>
        <li><Link>Towson, MD (2 Locations)</Link></li>
        <li><Link>Baltimore, MD</Link></li>
        <li><Link>Providence, RI</Link></li>
        </ul>
        </div>
          <div className='address-two'>
          <ul aria-label='Southeastern United States'>
        <li><Link>Miami, FL</Link></li>
        <li><Link>Fort Lauderdale, FL</Link></li>
        <li><Link>Orlando, FL</Link></li>
        <li><Link>Hollywood, FL</Link></li>
        <li><Link>Palm Beach, FL</Link></li>
        <li><Link>Richmond, VA</Link></li>
        <li><Link>Charleston, WV</Link></li>
        <li><Link>Charlotte, NC</Link></li>
        <li><Link>Raleigh, NC</Link></li>
        <li><Link>Myrtle Beach, SC</Link></li>
        <li><Link>Atlanta, GA</Link></li>
        <li><Link>Tampa, FL</Link></li>
        </ul>
          </div>
          <div className='address-three'>
           <ul aria-label='Canada'>
        <li><Link>Toronto, ON (3 Locations)</Link></li>
        <li><Link>Ottawa, ON (2 Locations)</Link></li>
        <li><Link>Niagara, ON</Link></li>
        <li><Link>Hamilton, ON</Link></li>
        <li><Link>Guelph, ON</Link></li>
        <li><Link>Edmonton, AB</Link></li>
        <li><Link>Calgary, AB</Link></li>
        <li><Link>Vancouver, BC</Link></li>
        <li><Link>Vancouver Island, BC</Link></li>
        <li><Link>Montréal, QC</Link></li>
        <li><Link>Baldwin, ON</Link></li>
        <li><Link>Markham, ON</Link></li>
        </ul>
            </div>
        </div>
        <div className='Global-and-Local-Offices'>
           <div className='address-fourth'>
             <ul aria-label='Gulf Coast United States'>
                <li><Link>Baton Rouge, LA</Link></li>
             </ul>
           </div>
           <div className='address-fifth'>
             <ul aria-label='West Coast United States'>
                <li><Link>Point Roberts, WA</Link></li>
                <li><Link>Los Angeles, CA</Link></li>
                <li><Link>Northern California</Link></li>
                <li><Link>Seattle, WA</Link></li>
             </ul>
           </div>
           <div className='address-six'>
             <ul aria-label='Caribbean and South America'>
                <li><Link>Bahamas</Link></li>
                <li><Link>Haiti</Link></li>
                <li><Link>Puerto Rico (2 Locations)</Link></li>
                <li><Link>Aruba</Link></li>
                <li><Link>Guyana</Link></li>
             </ul>
           </div>
           
        </div>
        <div className='Global-and-Local-Offices'>
        <div className='address-seven'>
             <ul aria-label='Midwest United States'>
                <li><Link>Detroit, MI</Link></li>
                <li><Link>Chicago, IL</Link></li>
             </ul>
           </div>
           <div className='address-eight'>
             <ul aria-label='Europe'>
                <li><Link>London, England</Link></li>
                <li><Link>Limassol, Cyprus</Link></li>
             </ul>
           </div>
        </div>
        </div>
        </div>
        

        <div className='sage-last-section'>
        <Row className="sage300-page-1 hero-div overlay" role="banner">
        <div className="sage-custom-header">
          <img
            src={"https://673dc7.a2cdn1.secureserver.net/wp-content/uploads/2017/12/shaking-hands.jpg"}
            width="1920"
            height="1080"
            alt=""
          />
        </div>
        <div>
        <Col xs={24} sm={12} className="help-text">
            <h1>How Can We Help?</h1>
            <h3>224 Cairnsmore Circle</h3>
            <h3>Ottawa ON K2J 0G5</h3>
            <h3>613-221-5950</h3>
            <h3> <a href="http://adsshelp.com/"> <em>Or click below to leave a message and one of our consultants will follow up.</em>
           <br/> <Button className="help-btn"   type={"primary"} onClick={(e)=>{
            
           }}>Get In Touch</Button><br/>
            </a></h3>
            </Col>
          </div>
        </Row>
        </div>
      </div>
   
  )
  }