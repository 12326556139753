import { Button, Col, Row } from 'antd';
import React from 'react'
import "../styles/Sage300.css";
import "../styles/ManagedServices.css";
import "../styles/NetworkSolution.css";
import RightSection from './RightSection';

export const NetworkSolutions = () => {
  return (
    <div>
       <Row className="sage300-page-1 hero-div overlay" role="banner">
        <div className="sage-custom-header">
          <img
            src={"https://673dc7.a2cdn1.secureserver.net/wp-content/uploads/2017/12/server-bg.jpg"}
            width="1920"
            height="1080"
            alt=""
          />
        </div>
        <div>
            <Col xs={24} sm={12} className="sage-center">
            <h1>Network Solutions</h1>
            </Col>
          </div>
        </Row>
        <div className='sage-div'>
          <div className='sage-left'>
          <h1>Why Choose ADSS Global?</h1>
          <p>
          ADSS Global offers exactly what our name implies… complete and total data solutions. Our extensive experience with networks of all types and sizes, combined with our installation knowledge and capabilities enables us to provide clients with end results that exceed their expectations.</p>
          <p>
          Unlike many hardware retailers, we go the extra mile. If you are creating or updating your network, we can suggest hardware and software that is best suited to meet your needs and provide you with our technicians’ extensive experience and knowledge. Listed below are just a few reasons ADSS Global should be your choice.
          </p>
          <p>
            <ul>
              <li>Certified knowledgeable technicians</li>
              <li>Proven conversion technology for moving software to different hardware platforms</li>
              <li>Providing seamless connectivity among different hardware platforms</li>
              <li>Providing parallel-processing options</li>
              <li>Demonstrated experience in 100% transportable software building (independent of hardware platform)</li>
              <li>Capable of developing programs, procedures, and/or utilities to enhance and/or speed the conversion process</li>
              <li>Practical, timely, and cost-effective solutions without disruption to your data processing operations</li>
              <li>Authorized reseller of many name-brand computer systems</li>
              <li>Not affiliated with any one hardware vendor or conversion tool, thereby offering the best available options to our customers</li>
            </ul></p>
          <h2>Systems Conversions and Software Transportability</h2>
          <p>
          Upgrading your servers or PC’s operating system is always a difficult choice and there are many factors to take into consideration. Not all programs are easily converted.
          </p>
          
            <h2>Design, Installation, and Maintenance Of Data Network</h2>
            <div>
            <h4><b>ADSS Global Will:</b></h4>
          <p>
            <ul>
              <li>Design a Local Area Network (LAN) or Wide Area Network (WAN) system to meet your specialized requirements</li>
              <li>Install network hardware and software, including Microsoft and other protocols</li>
              <li>Utilize our diverse experience in all types of LANs and WANs to ensure a trouble-free installation</li>
              <li>Follow up with network administration, maintenance, user training, and support</li>
              <li>Provide upgrades or programming enhancements to allow multiple access of existing applications on the network</li>
              </ul></p>
          </div>
          <div className='network-left-last-div'>
          <h2>Comprehensive Communication Networking</h2>
          <p className='network-paragraph-heading'>
              The Possibilities are Endless …
              </p>
              <p>Whether your company is seeking a complex communication network spanning over several states or simply the ability to access the Internet or utilize E-Mail, ADSS Global can assist you in achieving your goals. We possess the knowledge and experience required to design, install, and maintain various levels of communication network operations.</p>
              <p className='network-paragraph-heading'>
              Where to Begin …
              </p>
            <p>ADSS Global can help you determine what type of communication network would best suit your requirements. Our experience includes Fiber, T1, and Cable. Once the needs of your company have been identified, ADSS Global can make recommendations as to which type of network would be optimum for you.</p>
             <p className='network-paragraph-heading'>
             Hardware/Software Concerns …
              </p>
               <p>All communication network hardware, software, and services are available through ADSS Global, including:</p>
             <p>
              <ul>
                <li>Bridges, routers, and multiplexors</li>
                <li>E-Mail</li>
                <li>Access to the Internet & Web Filters</li>
                <li>IP Phone Systems</li>
              </ul>
              </p> 
          </div>
           
          </div>
          {/* Right Section  */}
          <div className='sage-right'>
          <img src="https://673dc7.a2cdn1.secureserver.net/wp-content/uploads/2017/12/server-room.jpg?time=1682639662"  alt='Sage300'/>
         <div>
          <RightSection />
         </div>
         </div>
        </div>
        <div className='sage-last-section'>
        <Row className="sage300-page-1 hero-div overlay" role="banner">
        <div className="sage-custom-header">
          <img
            src={"https://673dc7.a2cdn1.secureserver.net/wp-content/uploads/2017/12/server-bg.jpg"}
            width="1920"
            height="1080"
            alt=""
          />
        </div>
        <div>
        <Col xs={24} sm={12} className="help-text">
            <h1>How Can We Help?</h1>
            <h3>224 Cairnsmore Circle</h3>
            <h3>Ottawa ON K2J 0G5</h3>
            <h3>613-221-5950</h3>
            <h3> <a href="http://adsshelp.com/"> <em>Or click below to leave a message and one of our consultants will follow up.</em>
           <br/> <Button className="help-btn"   type={"primary"} onClick={(e)=>{
            
           }}>Get In Touch</Button><br/>
            </a></h3>
            </Col>
          </div>
        </Row>
        </div>
      </div>
   
  )
  }