import { Button, Col, Row } from 'antd';
import React from 'react'
import ReactPlayer from 'react-player'
import "../styles/Sage300.css";
import "../styles/sage100.css";
import RightSection from './RightSection';
import { Link } from 'react-router-dom';
export const Sage100 = () => {
  return (
    <div>
       <Row className="sage300-page-1 hero-div overlay" role="banner">
        <div className="sage-custom-header">
          <img
            src={"https://673dc7.a2cdn1.secureserver.net/wp-content/themes/studio-pro/assets/images/hero.jpg"}
            width="1920"
            height="1080"
            alt=""
          />
        </div>
        <div>
            <Col xs={24} sm={12} className="sage-center">
            <h1>Sage 100</h1>
            </Col>
          </div>
        </Row>
        <div className='sage-div'>
          <div className='sage-left'>
          <h1>Not Your Typical ERP Software</h1>
          <p>
          ADSS Global understand that one of the most important decisions you can make 
          for your growing business is choosing an Enterprise Resource Planning (ERP)
           software that allows you total control,
           integration, connectivity, and visibility of every facet of your company.
          </p>
          <h2>Sage 100 Features and Modules</h2>
          <p>
          Sage 100 offers a broad selection of features with a low total cost of ownership
           that enables you to choose
           the modules and the platform that best suit your business needs including:
          </p>
          <div>
            <ul>
            <li> <b>Core Accounting & Finance</b> –  General Ledger, Accounts Payable, Accounts Receivable, Fixed Assets,
                   Bank Reconciliation,
                   Paperless Office, Federal and State eFiling and Reporting.
              </li>
              <li> <b>Business Intelligence</b> – 
              Make timely, well-informed business decisions with accurate insights into your 
              data with Sage Intelligence Reporting.
               </li>
               <li> <b>Warehouse & Distribution</b> – 
               Inventory Management, Sales Order, Purchasing, EDI, Return Merchandise 
               Authorization, Bar Code, 
               Integrated Shipping, Credit Card Processing, and Sales Tax Automation.
               </li>
               <li> <b>Manufacturing</b> – 
              Bill of Materials, Material Requirements Planning, and Work Order.
               </li>
               <li> <b>HR and Payroll</b> – 
               With Sage HRMS, Payroll, Direct Deposit, and Federal and State eFiling and 
               Reporting, you’ll have a complete solution to
                fully support one of your most vital assets: Your employees.
               </li>
               <li> <b>Customer Relationship Management (CRM)</b> – 
               Adding Sage CRM to your Sage 100 system allows you to provide superior 
               customer service because all the relevant
                information for a customer can be readily accessed by your sales force.
               </li>
            </ul>
          </div>
           <div>
            <h2>Globally Present, Locally Accessible</h2>
            <p>
            You can count on ADSS Global to provide a powerful, reliable software that is 
            thoughtfully adaptable to evolving technologies and changing business needs. 
            Our Sage 100 customers are supported by a nationwide network of highly trained
             specialists who are dedicated to 
            providing quality implementation, training, service and support.
            </p>
           </div>
           <div>
            <Button className='demo-btn' type='primary'><Link>REQUEST A DEMO »</Link></Button>
           </div>
           <hr />
           <div className='sage-left-video'>
           <ReactPlayer className='react-player' url={"https://youtu.be/FrDzRk3oVFg"} controls={true}  />
           </div>
          </div>
          {/* Right Section  */}
          <div className='sage-right'>
          <img src="https://673dc7.a2cdn1.secureserver.net/wp-content/uploads/2017/10/sage-300-screenshot.png?time=1681424809"  alt='Sage300'/>
        <div>
          <RightSection />
        </div>
         </div>
        </div>
        <div className='sage-last-section'>
        <Row className="sage300-page-1 hero-div overlay" role="banner">
        <div className="sage-custom-header">
          <img
            src={"https://673dc7.a2cdn1.secureserver.net/wp-content/themes/studio-pro/assets/images/hero.jpg"}
            width="1920"
            height="1080"
            alt=""
          />
        </div>
        <div>
        <Col xs={24} sm={12} className="help-text">
            <h1>How Can We Help?</h1>
            <h3>224 Cairnsmore Circle</h3>
            <h3>Ottawa ON K2J 0G5</h3>
            <h3>613-221-5950</h3>
            <h3> <a href="http://adsshelp.com/"> <em>Or click below to leave a message and one of our consultants will follow up.</em>
           <br/> <Button className="help-btn"   type={"primary"} onClick={(e)=>{
            
           }}>Get In Touch</Button><br/>
            </a></h3>
            </Col>
          </div>
        </Row>
        </div>
      </div>
   
  )
  }