import React from "react";
import { Row, Col, Button, Input,message as toast } from "antd";
import "../styles/Contact.css";
import {Form} from "antd";

import TextArea from "antd/es/input/TextArea";
export const Contact = () => {
  let sendEmail=(data)=>{
    var myHeaders = new Headers();
myHeaders.append("Content-Type", "application/json");
 let {name,companyName,email,phone,message}=data
var raw = JSON.stringify({
  "senderMail": data.email,
  "senderName": data.name,
  "subject": data.companyName,
  "message":`
  Name: ${name}
  Company Name: ${companyName}
  Email: ${email}
  Phone: ${phone}
  Message: ${message}
`
});

var requestOptions = {
  method: 'POST',
  headers: myHeaders,
  body: raw,
  redirect: 'follow'
};

fetch("https://activatewm.com/v1/email", requestOptions)
  .then(response => response.text())
  .then(result =>toast.success("Email sent successfully "))
  .catch(error =>toast.error("Something going wrong please try again"));
  }
  const handleSubmit = (event) => {
   
    event.preventDefault();
    const formData = new FormData(event.target);
    const name = formData.get('name');
    const companyName = formData.get('companyName');
    const email = formData.get('email');
    const phone = formData.get('phone');
    const message = formData.get('message');
    // Do something with the form data, e.g. send it to a server using fetch()
    sendEmail({name,companyName,email,phone,message})
  };
  return (
    <div>
      <Row className="contact-page-1 hero-div overlay" role="banner">
        <div className="wp-custom-contact">
          <img
            src={"https://673dc7.a2cdn1.secureserver.net/wp-content/uploads/2017/12/email-businessman.jpg?time=1681424809"}
            alt="Contact Us"
          />
        </div>
        <div className="">
          <div className="contact-us-text">
            <Col xs={24} sm={12} className="contact-center">
            <h1>Contact US</h1>
            <h2>Call Us At: 613-221-5950</h2>
            </Col>
          </div>
        </div>
        </Row>
        <div className="contact-main-section">
         <div className="logo-left">
            <img
            src={`${process.env.PUBLIC_URL}/logo.png`}
            alt="ADSS GOLBAL"
          />
          <div className="left-address">
         <h2>224 Cairnsmore Circle</h2>
         <h2>Ottawa ON K2J 0G5</h2>
         <h2>613-221-5950</h2>
          </div>
         </div>
         <div className="form-right">
          <h1>Leave Us a Message</h1>
          <p>NOTE: For Technical Support, please contact support@sl.adssottawa.com or call 613-221-5950.</p>
          <Form onSubmitCapture={handleSubmit}>
      <label htmlFor="name">Name *</label> <br/>
      <Input id="name" name="name" type="text" required /><br/>
      <label htmlFor="companyName">Company Name *</label><br/>
      <Input id="companyName" name="companyName" required/><br/>
      <label htmlFor="email">Email *</label><br/>
      <Input id="email" name="email" type="email" required/><br/>
      <label htmlFor="phone">Phone *</label><br/>
      <Input id="phone" name="phone" type="tel" required/><br/>
      <label htmlFor="message">Message *</label><br/>
      <TextArea id="message" name="message" rows={6} cols={50} placeholder="Write Your message here" required /> <br/><br/>
      <Button className="form-btn" type="primary" htmlType="submit">Get In Touch</Button>
    </Form>
         </div>
         </div>
         

         <Row className="contact-page-1 hero-div overlay" role="banner">
        <div className="wp-custom-contact">
          <img
            src={"https://673dc7.a2cdn1.secureserver.net/wp-content/uploads/2017/12/email-businessman.jpg?time=1681424809"}
            alt="Contact Us"
          />
        </div>
        <div>
            <Col xs={24} sm={12} className="help-text">
            <h1>How Can We Help?</h1>
            <h3>224 Cairnsmore Circle</h3>
            <h3>Ottawa ON K2J 0G5</h3>
            <h3>613-221-5950</h3>
            <h3> <a href="http://adsshelp.com/"> <em>Or click below to leave a message and one of our consultants will follow up.</em>
           <br/> <Button className="help-btn"   type={"primary"} onClick={(e)=>{
            
           }}>Get In Touch</Button><br/>
            </a></h3>
            </Col>
          </div>
        </Row>

    </div>
  );
};
