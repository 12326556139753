import { Button, Col, Row } from 'antd';
import React from 'react'
import { Link } from 'react-router-dom';
import "../styles/Sage300.css";
import "../styles/ManagedServices.css";
import RightSection from './RightSection';

export const ClientCareServices = () => {
  return (
    <div>
       <Row className="sage300-page-1 hero-div overlay" role="banner">
        <div className="sage-custom-header">
          <img
            src={"https://673dc7.a2cdn1.secureserver.net/wp-content/uploads/2017/12/server-bg.jpg"}
            width="1920"
            height="1080"
            alt=""
          />
        </div>
        <div>
            <Col xs={24} sm={12} className="sage-center">
            <h1>Client Care Services</h1>
            <h2>Budget Your IT Support Expenses on a Fixed, Monthly Basis</h2>
            </Col>
          </div>
        </Row>
        <div className='sage-div'>
          <div className='sage-left'>
          <h1>IT Support You Need Without the Full Time Staff</h1>
          <p>
          IT Client Care allows you to budget your <Link to={"#"}> IT support</Link> expenses on a fixed, monthly basis. Some of the benefits of IT Client Care are as follows:
          </p>
          <h2>Pro-Active versus Re-Active</h2>
          <p>
          ADSS Global will use its best efforts to perform diagnostic and preventive maintenance on your servers, workstations, and software systems. Preventative Maintenance will include, but not be limited to:

          </p>
          <div>
            <ul>
              <li>Regular verification and installation of critical operating system and software updates</li>
              <li>Running random test restores on backup sets</li>
              <li>Checking server logs and addressing any problems or potential problems</li>
              <li>Running Baseline Security Analyzer on all computers and addressing any issues discovered by that scan</li>
              <li>Verifying virus definition updates</li>
              <li>Checking disk space utilization</li>
            </ul>
            <p>
            Generally, clients only call ADSS Global when a problem develops. The goal of IT Client Care is to find and resolve areas of concern before they become a major problem.
            </p>
            <h2>IT Planning and Documentation</h2>
             <p>
             ADSS Global will document your network to allow you to operate efficiently that will allow you to intelligently plan for the future. Similar to improving your current IT operations with preventive maintenance, IT planning allows you to make the right choices when changes occur in your business model or your IT infrastructure.
             </p>
             <h2>Greater Knowledgebase</h2>
             <p>
             By performing preventive maintenance and IT planning and documentation, ADSS Global will be your IT Manager. Through regular attendance of your IT systems, ADSS Global will be able to resolve problems with systems that might have been inadvertently overlooked as opposed to only being involved with your company on an emergency basis.
             </p>
             <h2>Security Updates and Virus Protection</h2>
             <p>
             ADSS Global will update your servers and PC workstations on a regular basis in order to provide you with the best possible protection against hackers and viruses. All too often, ADSS Global is called only after a break-in or virus outbreak has occurred. The time spent to fix these issues is far greater than if we could have made attempts to prevent the problem in the first place.
             </p>
          </div>
           <div>
            <h2>Priority Support</h2>
              <p>
              ADSS Global provides clients who have signed an IT Client Care Agreement priority support in an emergency situation. The goal of IT Client Care is to always provide you with the highest level of Support.
              </p>
              <p>
              IT Client Care is designed to allow ADSS Global to be your IT Manager and IT support staff without the need for your organization to hire a full time staff. With our experienced staff, ADSS Global will have multiple network technicians assigned to your account so that you will not be dependent on any single individual for service or support with your IT infrastructure. This team approach will allow your organization to receive the most precise and accurate information available and help you make important business decisions concerning your current and future IT needs.
              </p>
              </div>
          </div>
          {/* Right Section  */}
          <div className='sage-right'>
          <img src="https://673dc7.a2cdn1.secureserver.net/wp-content/uploads/2017/12/server-room.jpg?time=1682639662"  alt='Sage300'/>
         <div>
          <RightSection />
         </div>
          </div>
        </div>
        <div className='sage-last-section'>
        <Row className="sage300-page-1 hero-div overlay" role="banner">
        <div className="sage-custom-header">
          <img
            src={"https://673dc7.a2cdn1.secureserver.net/wp-content/uploads/2017/12/server-bg.jpg"}
            width="1920"
            height="1080"
            alt=""
          />
        </div>
        <div>
        <Col xs={24} sm={12} className="help-text">
            <h1>How Can We Help?</h1>
            <h3>224 Cairnsmore Circle</h3>
            <h3>Ottawa ON K2J 0G5</h3>
            <h3>613-221-5950</h3>
            <h3> <a href="http://adsshelp.com/"> <em>Or click below to leave a message and one of our consultants will follow up.</em>
           <br/> <Button className="help-btn"   type={"primary"} onClick={(e)=>{
           }}>Get In Touch</Button><br/>
            </a></h3>
            </Col>
          </div>
        </Row>
        </div>
      </div>
   
  )
  }