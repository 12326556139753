import React from 'react'
import { Col, Row } from 'antd';
import "../styles/Sage300.css";
import "../styles/Hosting.css";
import RightSection from './RightSection';





const CustomerSuccesStories = () => {
  return (
    <div>
        <Row className="sage300-page-1 hero-div overlay" role="banner">
        <div className="sage-custom-header">
          <img
            src={"https://673dc7.a2cdn1.secureserver.net/wp-content/themes/studio-pro/assets/images/hero.jpg"}
            alt="Hosting" />
        </div>
        <div>
            <Col xs={24} sm={12} className="sage-center">
            <h1>Customer Success Stories</h1>
            </Col>
          </div>
        </Row>
        <div className="sage-div">
        <div className="sage-left" >
           
       </div>
         <div className='sage-right'>
          <img src="https://673dc7.a2cdn1.secureserver.net/wp-content/uploads/Satisfied_Customers_high.jpg?time=1682962927"  alt='Sage300'/>
         <div>
         <RightSection />
         </div>
         </div>
         </div>
    </div>
  )
}

export default CustomerSuccesStories