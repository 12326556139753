import { Button, Col, Menu, Row,Dropdown } from 'antd';
import SubMenu from 'antd/es/menu/SubMenu';
import React, { useEffect, useState } from 'react';
import "../App.css";
import { CaretDownOutlined, MenuOutlined} from '@ant-design/icons';
import { useMediaQueries} from '@react-hook/media-query'
import { useNavigate } from 'react-router-dom';

export const HeadersComponent = () => {
  let nav=useNavigate()
  const [visible, setVisible] = useState(false);
  const [isMobileView, setIsMobileView] = useState(false);
  const {matches} = useMediaQueries({
    screen: 'screen',
    width: '(max-width: 890px)'
  })
  const menu = (
    <Menu mode="vertical">
      <SubMenu title="SAGE SOFTWARES">
        <Menu.Item
        onClick={()=>{
          nav('/software/sage300/')
        }}>SAGE 300</Menu.Item>
        <Menu.Item
         onClick={()=>{ 
          nav('/software/sage100')
        }}
        >SAGE 100</Menu.Item>
        <Menu.Item onClick={()=>{ 
          nav('/software/sage-intact/')
        }}>SAGE INTACT</Menu.Item>
        <Menu.Item
        onClick={()=>{ 
          nav('/software/sage-crm/')
        }}
        >SAGE CRM</Menu.Item>
        <Menu.Item
        onClick={()=>{ 
          nav('/software/sage-hrms-payroll/')
        }}
        >SAGE HRMS</Menu.Item>
      </SubMenu>
      <SubMenu title="IT SERVICES">
        <Menu.Item
        onClick={()=>{ 
          nav('/services/hosting/')
        }}>
          HOSTING</Menu.Item>
        <Menu.Item
         onClick={()=>{ 
          nav('/services/managed-services/')
        }}
        >MANAGED SERVICES</Menu.Item>
        <Menu.Item
        onClick={()=>{ 
          nav('/services/disaster-recovery/')
        }}
        >DISASTER RECOVERY</Menu.Item>
        <Menu.Item
        onClick={()=>{ 
          nav('/services/client-care/')
        }}
        >CLIENT CARE SERVICES</Menu.Item>
        <Menu.Item
        onClick={()=>{ 
          nav('/services/network/')
        }}
        >NETWORK SOLUTIONS</Menu.Item>
      </SubMenu>
      <SubMenu title="COMPANY">
        <Menu.Item
        onClick={()=>{ 
          nav('company/about-us')
        }}
        >ABOUT US</Menu.Item>
        <Menu.Item onClick={()=>{ 
          nav('company/offices')
        }}>OFFICES</Menu.Item>
        <SubMenu title="OUR CUSTOMERS">
          <Menu.Item onClick={()=>{ 
          nav('company/customer/succes-stories')
        }}>SUCCESS STORIES</Menu.Item>
          <Menu.Item onClick={()=>{ 
          nav('company/customer/testimonal')
        }}>TESTIMONIALS</Menu.Item>
        </SubMenu>
        <Menu.Item onClick={()=>{ 
          nav('company/press-releases')
        }}>PRESS RELEASES</Menu.Item>
        <Menu.Item onClick={()=>{ 
          nav('company/awards')
        }}>AWARDS & ACCOMPLISHMENT</Menu.Item>
      </SubMenu>
      <SubMenu title="SUPPORT">
        <Menu.Item onClick={()=>{
          nav('/contact')
        }}>
        CONTACT SUPPORT</Menu.Item>
        <Menu.Item onClick={()=>{ 
          nav('/support/training-videos/')
        }}>TRAINING VIDEOS</Menu.Item>
        <Menu.Item onClick={()=>{ 
          nav('/support/newsletters/')
        }}>NEWSLETTERS</Menu.Item>
      </SubMenu>
    </Menu>
  );
  useEffect(() => {
    console.log(matches.screen)
    const handleResize = () => {
      setIsMobileView(window.innerWidth <= 890);
    };
    handleResize(); // set initial value
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  const toggleMenu = () => {
    setVisible(!visible);
  };
  return (
    <div>
     
      {isMobileView ? (
        // Render mobile view
        <Row  flex={true} gutter={[20, 20]} >
         <Col span={10}>
        <div className="logo" >
        <img className='img' src={`${process.env.PUBLIC_URL}/adss_global_logo.jpg`} alt="Adss Global" />
        </div>
        </Col>
        <Col className='header-row' span={14} >
        <span className='header_col'>
        <Dropdown
            overlay={menu}
            trigger={["click"]}
            visible={visible}
            onVisibleChange={toggleMenu}
          >
             <MenuOutlined  class={"dropdown-toggle"} style={{color:visible?"blue":"black", fontSize: '40px', width: '40px', height: '40px' }} />
          </Dropdown>
        </span>
         <span className='header_col'>
         <Button type="primary" onClick={()=>{
          nav('/contact')
         }}>CONTACT US »</Button> 
         </span>
        </Col>
        <Col>
        
        </Col>
      </Row>
      ) : (
        // Render desktop view

           <Row justify="space-between">
        <Col md={5} lg={8} xl={10} xxl={12}>
        <div className="logo" >
        <a href='/'>
        <img className='img' src={`${process.env.PUBLIC_URL}/adss_global_logo.jpg`} alt="Adss Global" />
          </a>  
        
        </div>
        </Col>
    <Col style={{padding:30}} md={16} lg={14} xl={12} xxl={9}>
     
      <Menu  mode="horizontal" 
      defaultSelectedKeys={['1.1']} >
      <SubMenu className="my-menu-item" key="1" title={
          <span>
        SAGE SOFTWARES <CaretDownOutlined />
      </span>
      } >
        <Menu.Item key="1.1" 
        onClick={()=>{
          nav('/software/sage300/')
        }}
        >SAGE 300</Menu.Item>
        <Menu.Item key="1.2"
         onClick={()=>{
          nav('/software/sage100/')
        }}
        >SAGE 100</Menu.Item>
        <Menu.Item key="1.3"
        onClick={()=>{
          nav('/software/sage-intact/')
        }}
        >SAGE INTACT</Menu.Item>
        <Menu.Item key="1.4"
         onClick={()=>{
          nav('/software/sage-crm/')
        }}
        >SAGE CRM</Menu.Item>
        <Menu.Item key="1.5"
         onClick={()=>{
          nav('/software/sage-hrms-payroll/')
        }}
        >SAGE HRMS</Menu.Item>
       
      </SubMenu>
      <SubMenu key="2" 
      title={
        <span>
        IT SERVICES<CaretDownOutlined />
        </span>
      } >
        <Menu.Item key="2.1"
        onClick={()=>{
          nav('/services/hosting/')
        }}
        >HOSTING</Menu.Item>
        <Menu.Item key="2.2"
        onClick={()=>{
          nav('/services/managed-services/')
        }}
        >MANAGED SERVICES</Menu.Item>
        <Menu.Item key="2.3"
         onClick={()=>{
          nav('/services/disaster-recovery/')
        }}
        >DISASTER RECOVERY</Menu.Item>
        <Menu.Item key="2.4" style={{ width: "auto" }}
        onClick={()=>
        {
          nav('/services/client-care/')
        }
        }
        >CLIENT CARE SERVICES</Menu.Item>
       
       
        <Menu.Item key="2.5" 
        onClick={()=> {
          nav('/services/network/'
        )}}
        >NETWORK SOLUTIONS</Menu.Item>



      </SubMenu>
      <SubMenu   key="3" title={
        <span>
        COMPANY <CaretDownOutlined />
        </span>
      }>


        <Menu.Item key="3.1" onClick={()=>{
          nav('company/about-us')
        }}>ABOUT US</Menu.Item>


        <Menu.Item key="3.2"onClick={()=>{
          nav('company/offices')
        } }>OFFICES</Menu.Item>
        <SubMenu   key="3.3" title={
        <span>
        OUR CUSTOMERS
        </span>
      }>

         <Menu.Item key="3.3.1" onClick={()=>{
          nav('company/customer/succes-stories')
        } }>SUCCESS STORIES</Menu.Item>

        <Menu.Item key="3.3.2"
        onClick={()=>{
          nav('company/customer/testimonal')
        } }>TESTIMONIALS</Menu.Item>

      </SubMenu>
        <Menu.Item key="3.4"
        onClick={()=>{
          nav('company/press-releases')
        } }>PRESS RELEASES</Menu.Item>

        <Menu.Item key="3.5" style={{ width: "auto" }}
        onClick={()=>{
          nav('company/awards')
        } }>AWARDS & ACCOMPLISHMENT</Menu.Item>

      </SubMenu>
      <SubMenu   key="4"  title={
        <span>
        SUPPORT <CaretDownOutlined />
        </span>
      } >
        <Menu.Item key="4.1" style={{ width: "auto" }} onClick={()=>{
          nav('/support/contact-support/')
        }}>CONTACT SUPPORT</Menu.Item>
        {<Menu.Item key="4.2"
          onClick={()=>{
            nav('/support/training-videos/')}}
         >
          TRAINING VIDEOS</Menu.Item> }
        <Menu.Item key="4.3"
        onClick={()=>{
          nav('/support/newsletters/')
        }}
        >NEWSLETTERS</Menu.Item>
      </SubMenu>
      </Menu>
    </Col>
    <Col md={3} lg={2}>
    <Button type="primary" onClick={()=>{
      nav('/contact')
    }}>CONTACT US »</Button>
    </Col>
  </Row>
     
      )}
    </div>
  );
};
