import { Button, Col, Row } from 'antd';
import React from 'react'
import { Link } from 'react-router-dom';
import "../styles/Sage300.css";
import RightSection from './RightSection';
export const Sage300 = () => {
  return (
    <div>
       <Row className="sage300-page-1 hero-div overlay" role="banner">
        <div className="sage-custom-header">
          <img
            src={"https://673dc7.a2cdn1.secureserver.net/wp-content/uploads/2017/10/sage-300-screenshot.png?time=1681424809"}
            width="1920"
            height="1080"
            alt=""
          />
        </div>
        <div>
            <Col xs={24} sm={12} className="sage-center">
            <h1>Sage 300</h1>
            </Col>
          </div>
        </Row> 
        <div className='sage-div'>
          <div className='sage-left'>
          <h1>The #1 Sage Partner in North America</h1>
          <p>
          ADSS Global specializes in the <b>Sage 300</b> (formerly Accpac) software solutions
          and has been consistently selected as one of Sage Software’s <b>Elite Business
          Partners.</b> Our staff is represented by highly trained consultants with extensive 
          experience and background that is 
          required to successfully implement business management applications.
          </p>
          <h2>End-to-End Business Management</h2>
          <p>
          Sage 300 is an award-winning, <b>web-based ERP system</b> built on world-class
           technology. Designed for companies of all sizes, Sage 300 delivers an integrated 
          suite of end-to-end business management applications including:
          </p>
          <div>
            <ul>
              <li><Link target={"_blank"} to={"https://www.adssglobal.net/wp-content/uploads/Sage-300-Financial-Management.pdf"}>Financial Management</Link></li>
              <li><Link target={"_blank"} to={"https://www.adssglobal.net/wp-content/uploads/Sage-300-Multicompany.pdf"}>Multi-company and International Operations</Link></li>
              <li><Link target={"_blank"} to={"https://www.adssglobal.net/wp-content/uploads/Sage-300-Multicurrency.pdf"}>Multicurrency Management</Link></li>
              <li><Link target={"_blank"} to={"https://www.adssglobal.net/wp-content/uploads/Sage-300-Ops-Distribution.pdf"}>Operations and Distribution</Link></li>
              <li><Link target={"_blank"} to={"https://www.adssglobal.net/wp-content/uploads/Sage-300-Payroll.pdf"}>Payroll</Link></li>
              <li><Link target={"_blank"} to={"https://www.adssglobal.net/software/sage-300-project-job-costing/"}>Project and Job Costing</Link></li>
              <li><Link target={"_blank"} to={""}>CRM </Link> – Customer Relationship Management</li>
              <li><Link target={"_blank"} to={""}>Manufacturing</Link></li>
              <li><Link target={"_blank"} to={""}>HRMS</Link></li>
              <li><Link target={"_blank"} to={""}>eCommerce</Link></li>
              <li><Link target={"_blank"} to={""}>AP/PO Automation & Workflow</Link></li>
              <li><Link target={"_blank"} to={""}>Inter Entity Transactions</Link></li>
              <li><Link target={"_blank"} to={""}>Fixed Asset System</Link></li>
              <li><Link target={"_blank"} to={""}>Sage Business Intelligence</Link></li>
              <li><Link target={"_blank"} to={""}>Sage Budgeting & Forecasting</Link></li>
              <li><Link target={"_blank"} to={""}>e-Analytics Portal</Link></li>
              <li>Hundreds of Industry-Specific Add-ons</li>
            </ul>
          </div>
           <div>
            <h2>Freedom of Choice</h2>
            <p>
            <b>Sage 300</b> offers high performance, advanced functionality, and unmatched 
            freedom of choice. You can choose the applications, technology, and deployment
             options you need most, and can easily customize and enhance your system as your 
             business needs evolve.  Plus with flexible deployment options, you can 
            implement the software onsite, in the cloud, or as a hosted application.
            </p>
           </div>
           <div>
            <Button className='demo-btn' type='primary'><a>REQUEST A DEMO »</a></Button>
           </div>
           <hr />
           <div className='sage-left-img'>
            <img width={600} height={400} alt='Sage300 Computer Screen' src={"https://673dc7.a2cdn1.secureserver.net/wp-content/uploads/2017/10/sage-300-screenshot.png"} />
           </div>
          </div>
          {/* Right Section  */}
          <div className='sage-right'>
          <img src="https://673dc7.a2cdn1.secureserver.net/wp-content/uploads/2017/10/sage-300-screenshot.png?time=1681424809"  alt='Sage300'/>
         <div><RightSection /></div>
          
         </div>
        </div>
        <div className='sage-last-section'>
        <Row className="sage300-page-1 hero-div overlay" role="banner">
        <div className="sage-custom-header">
          <img
            src={"https://673dc7.a2cdn1.secureserver.net/wp-content/uploads/2017/10/sage-300-screenshot.png?time=1681424809"}
            width="1920"
            height="1080"
            alt=""
          />
        </div>
        <div>
        <Col xs={24} sm={12} className="help-text">
            <h1>How Can We Help?</h1>
            <h3>224 Cairnsmore Circle</h3>
            <h3>Ottawa ON K2J 0G5</h3>
            <h3>613-221-5950</h3>
            <h3> <a href="http://adsshelp.com/"> <em>Or click below to leave a message and one of our consultants will follow up.</em>
           <br/> <Button className="help-btn"   type={"primary"} onClick={(e)=>{
            
           }}>Get In Touch</Button><br/>
            </a></h3>
            </Col>
          </div>
        </Row>
        </div>
      </div>
   
  )
  }