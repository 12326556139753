import { Button, Col,  Row } from 'antd';
import React from 'react'
import "../styles/Sage300.css";
import RightSection from './RightSection';

export const DisasterRecovery = () => {
  return (
    <div>
      <Row className="sage300-page-1 hero-div overlay" role="banner">
        <div className="sage-custom-header">
          <img
            src={"https://673dc7.a2cdn1.secureserver.net/wp-content/uploads/2017/12/server-bg.jpg"}
            width="1920"
            height="1080"
            alt=""
          />
        </div>
        <div>
          <Col xs={24} sm={12} className="sage-center">
            <h1>Disaster Recovery / Business Continuity</h1>
          </Col>
        </div>
      </Row>
      <div className='sage-div'>
        <div className='sage-left'>
          <h1>Disaster Recovery Services</h1>
          <p>
            Unfortunately, disasters do happen and in that split second, the key to your company’s survival is in prior planning and preparation. If you should arrive at your office and cannot gain access to the building or your office space, who do you call? Where do you go? What should you do first?
          </p>
          <p>This is where ADSS Global can help. Having the right personnel available and your live systems running in the ADSS Data Center will make the recovery process go smoothly is critical after a Disaster strikes.</p>
          <p>ADSS Global has been working hard for over (15) years to develop our Disaster Recovery program which sets itself apart from the rest by providing an excellent Data Center as well as service on a first-name basis.</p>
          <h2>Helping You Maintain Business Continuity</h2>
          <p>
            Along with having an on-demand recovery and the right personnel, a critically important step in recovering from a disaster is maintaining Business Continuity. Business Continuity is more than a backup solution. It is the knowing and documenting exactly which processes, systems, services, employees, and customers are affected, what steps are to be taken and in what order they should be taken.
          </p>
          <p>Anything that can be done should be done to minimize the potential of having to find the answer to a question in a crisis. A true Business Continuity/Disaster Recovery Plan should be developed to plan out exactly who, what, when, where, and how. Have the experts at ADSS Global work with you to develop, test, and maintain a Business Continuity Plan so that you already know the answers to who, what, when, where and how during a disaster.</p>
          <p>In the final analysis, you may have spent years on real dollars and “sweat equity” in building your business, but will your business survive if you were out of operation for a few days, a week, or a month? And even if your business should survive, at what costs in lost clients and revenue versus having a true Business Continuity/Disaster Recovery Plan in place.</p>

        </div>
        {/* Right Section  */}
        <div className='sage-right'>
          <img src="https://673dc7.a2cdn1.secureserver.net/wp-content/uploads/2017/12/server-room.jpg?time=1682639662" alt='Sage300' />
          <div>
            <RightSection />
            </div>
          </div>
      </div>
      <div className='sage-last-section'>
        <Row className="sage300-page-1 hero-div overlay" role="banner">
          <div className="sage-custom-header">
            <img
              src={"https://673dc7.a2cdn1.secureserver.net/wp-content/uploads/2017/12/server-bg.jpg"}
              width="1920"
              height="1080"
              alt=""
            />
          </div>
          <div>
            <Col xs={24} sm={12} className="help-text">
              <h1>How Can We Help?</h1>
              <h3>224 Cairnsmore Circle</h3>
              <h3>Ottawa ON K2J 0G5</h3>
              <h3>613-221-5950</h3>
              <h3> <a href="http://adsshelp.com/"> <em>Or click below to leave a message and one of our consultants will follow up.</em>
                <br /> <Button className="help-btn" type={"primary"} onClick={(e) => {

                }}>Get In Touch</Button><br />
              </a></h3>
            </Col>
          </div>
        </Row>
      </div>
    </div>

  )
}