import { Button, Col, Row } from 'antd';
import React from 'react'
import ReactPlayer from 'react-player'
import { Link } from 'react-router-dom';
import "../styles/Sage300.css";
import "../styles/SageIntact.css";
import RightSection from './RightSection';
export const SageSRM = () => {
  return (
    <div>
       <Row className="sage300-page-1 hero-div overlay" role="banner">
        <div className="sage-custom-header">
          <img
            src={"https://673dc7.a2cdn1.secureserver.net/wp-content/uploads/2017/10/sage-300-screenshot.png"}
            width="1920"
            height="1080"
            alt=""
          />
        </div>
        <div>
            <Col xs={24} sm={12} className="sage-center">
            <h1>Sage CRM</h1>
            <h2>Customer Relationship Management Software</h2>
            </Col>
          </div>
        </Row>
        <div className='sage-div'>
          <div className='sage-left'>
          <h1>Find New Customers, Close Sales Faster, Build Lasting Relationships</h1>
          <p>
          <b>Sage CRM</b> is used by over 10,000 organizations in 70 countries worldwide to 
          manage their critical sales, marketing and customer service activities every day.
           This award-winning customer relationship management (CRM) software equips your business 
           with the tools you need to find new customers, close sales faster and build lasting, more
            profitable relationships across all channels.</p>
            <p>
            Thanks to a seamless ERP integration with both <Link to={"/software/sage100/"}>Sage 100</Link> and <Link to={"/software/sage300/"}>Sage 300</Link>, 
            the Sage CRM front-office is powered by data from the back-office to give sales,
            marketing, customer service and other front-office staff a true 360 degree view of customers
            and decisive advantage for your business.
            </p>
          
          <h1>Key Benefits of Sage CRM</h1>
          <p><b>Easy To Use</b> – recognized by thousands of customers worldwide as
           easy-to-use, teams can easily personalize 
          their workspace and quickly navigate their way around the system.</p>
          <p><b>Quick to Deploy</b> –  
          designed to be up and running in a matter of days, delivering rapid return on investment.
          </p>
          <p><b>Easy to Customize</b> – out-of-the-box workflow can be customized to mold around your business processes. 
          Plus, the open architecture enables seamless integration with third party applications.</p>
          <p><b>Integrates With Your Business </b> –  integration with Sage ERP products delivers end-to-end business integration out-of-the-box. </p>
           
           
             <div>
              <h2>The Sage Difference</h2>
             <p>
            <ul>
            <li>The leading supplier of CRM solutions to SMB organizations worldwide</li>
            <li>Over 6.3 million customers</li>
            <li>Over 3.1 million Sage CRM Solutions users worldwide</li>
            <li>Over 13,400 employees</li>
            <li>Over 30,000 Sage-certified partners specializing in business applications</li>
            <li>Direct presence in 24 countries</li>
            <li>Relationships with over 40,000 accountancy practices</li>
            <li>30 years’ experience</li>
            </ul>
         </p>
           <div>
            <Button className='demo-btn' type='primary'><Link>REQUEST A DEMO »</Link></Button>
           </div>
           <hr />
           <div className='sage-left-video'>
           <ReactPlayer className='react-player' url={"https://www.youtube.com/watch?v=d4QZW5oqzzw"} controls={true}  />
           </div>
           </div>
          </div>
          {/* Right Section  */}
          <div className='sage-right'>
          <img src="https://673dc7.a2cdn1.secureserver.net/wp-content/uploads/2017/11/sage-crm-sales-dashboard.png?time=1682180858"  alt='SageCRM'/>
          <div>
            <RightSection />
          </div>
          </div>
        </div>
        <div className='sage-last-section'>
        <Row className="sage300-page-1 hero-div overlay" role="banner">
        <div className="sage-custom-header">
          <img
            src={"https://673dc7.a2cdn1.secureserver.net/wp-content/uploads/header-background.jpg"}
            width="1920"
            height="1080"
            alt=""
          />
        </div>
        <div>
        <Col xs={24} sm={12} className="help-text">
            <h1>How Can We Help?</h1>
            <h3>224 Cairnsmore Circle</h3>
            <h3>Ottawa ON K2J 0G5</h3>
            <h3>613-221-5950</h3>
            <h3> <a href="http://adsshelp.com/"> <em>Or click below to leave a message and one of our consultants will follow up.</em>
           <br/> <Button className="help-btn"   type={"primary"} onClick={(e)=>{
            
           }}>Get In Touch</Button><br/>
            </a></h3>
            </Col>
          </div>
        </Row>
        </div>
      </div>
   
  )
  }