import { Col, Divider, Row } from 'antd';
import React from 'react'
import "../styles/Sage300.css";
import "../styles/Hosting.css";
import "../styles/PressRelease.css";
import { Link } from 'react-router-dom';






const PressRelease = () => {
    return (
        <div>
            <Row className="sage300-page-1 hero-div overlay" role="banner">
                <div className="sage-custom-header">
                    <img src={"https://673dc7.a2cdn1.secureserver.net/wp-content/uploads/company-news.jpg"} alt="Hosting" />
                </div>
                <div>
                    <Col xs={24} sm={12} className="sage-center">
                        <h1>Press Releases</h1>
                    </Col>
                </div>
            </Row>
                <div className='PressRelease-main-div'>
                <diV className='warp'>
            
                            {/* PressRelease 1 */}
                <div>
                    <h1 className='press-heading-one'>ADSS Global Achieves Sage Diamond Partner Status</h1>
                    <h1 className='press-heading-two'>December 2019</h1>
                    <span  className='PressRelease-flex'>
                    <p className='press-left'>
                    <img className='press-Right-img' width={300} height={67} src={"https://673dc7.a2cdn1.secureserver.net/wp-content/uploads/Sage-Diamond-Partner-300x67.png"} alt='i am displayed' />
                    Sage has announced ADSS Global as a winner of the Sage Diamond Partner status.  The Diamond Partner program was designed to reward and recognize outstanding performance of Sage’s top business partners.  This distinction is awarded to ADSS Global for achieving tremendous growth with their Sage business by demonstrating a strong commitment to revenue achieved through new license and install-based sales.
                    </p>
                    <p>
                    Nancy Teixeira, VP, NA Partner Strategy & Sales at Sage said, “Our business partners have been central to accelerating our mission of helping customers thrive. I have seen true dedication and commitment to the Sage brand throughout the year, as well as an overwhelming customer obsession and sales excellence.  I am very proud to have ADSS Global as part of our amazing extended partner team.”
                    </p>
                    </span>               
                </div>
                <Divider style={{ border: '2px solid white', marginBottom: '5%' , boxShadow: '2px 5px 2px 0px black',}} />
                
                            {/* PressRelease 2 */}

                <div>
                    <h1 className='press-heading-one'>ADSS Global Achieves Sage CEO Circle Again for 2018</h1>
                    <h1 className='press-heading-two'>January 2019</h1>
                    <span  className='PressRelease-flex'>
                    <p className='press-left'>
                    <img className='press-Right-img' width={300} height={243} src={"https://673dc7.a2cdn1.secureserver.net/wp-content/uploads/Sage-ceo-logo-2018_25-300x243.png"} alt='i am displayed' />
                    Once again, ADSS Global is a recipient of the Sage CEO Circle award for 2018. The award recognizes Sage’s top-performing business partners that demonstrate outstanding overall achievement across year-over-year revenue growth, accelerate new customer acquisition across Sage Business Cloud, and maintain above average percentage of their installed base on subscription.
                   <br />
                    <p className='inside-p-in-p'>
                    This year’s exclusive class of Sage CEO Circle winners includes 17 recipients worldwide, and just 7 here in North America. Representatives from ADSS Global will join other top Sage partners on a prestigious trip to Koh, Samui Thailand, as winners of the Sage CEO Circle Program.
                    </p>
                    </p>
                    
                    </span>               
                </div>

                <Divider style={{ border: '2px solid white', marginBottom: '5%' , boxShadow: '2px 5px 2px 0px black',}} />
                
                {/* PressRelease 3 */}

    <div>
        <h1 className='press-heading-one'>ADSS Global Named VAR Star 2018</h1>
        <h1 className='press-heading-two'>January 2019</h1>
        <span  className='PressRelease-flex'>
        <p className='press-left'>
        <img className='press-Right-img' width={300} height={220} src={"https://673dc7.a2cdn1.secureserver.net/wp-content/uploads/VAR-Stars-2018-Logo-300x220.jpg"} alt='i am displayed' />
        ADSS Global has, once again, been selected as a member of the Bob Scott’s VAR Stars for 2018 – a group of 100 organizations honored for their accomplishments in the field of mid-market financial software.
       <br />
        <p className='inside-p-in-p'>
        A recipient now for several years running, ADSS Global was selected based on factors such growth, industry leadership and recognition, and innovation. Selection is not based on revenue and those firms chosen represent a wide range of size and many different software publishers of accounting software.
        </p>
        <p className='inside-p-in-p'>
        “Each year, 100 VAR Stars are picked from the best organizations that market financial software. It is always an honor to recognize those who contribute to the development of our business,” Bob Scott said.
        </p>
        <p className='inside-p-in-p'>
        Bob Scott has been informing and entertaining the mid-market financial accounting and ERP software community via his email newsletters for 19 years.
        </p>
        </p>
        
        </span>               
    </div>
    <Divider style={{ border: '2px solid white', marginBottom: '5%' , boxShadow: '2px 5px 2px 0px black',}} />
                
                {/* PressRelease 4 */}

    <div>
        <h1 className='press-heading-one'>ADSS Global Announced as a Winner of Sage CEO Circle</h1>
        <h1 className='press-heading-two'>November 2017</h1>
        <span  className='PressRelease-flex'>
        <p className='press-left'>
        <img className='press-Right-img' width={300} height={220} src={"https://www.adssglobal.net/wp-content/uploads/Sage_CEOCircle_Winner-resize.png"} alt='i am displayed' />
        ADSS Global announced that it is a winner of the Sage CEO Circle award program. The program was designed to reward and recognize outstanding performance of Sage’s top business partners that serve customers, recommend products and advocate for Sage.<br />
        <p className='inside-p-in-p'>
        As well as being the recipient of this illustrious award, representatives from ADSS Global will also join other award winners and Sage Executives on the winners’ trip taking place in February 2018, traveling to Mexico to take part in an all-expenses paid luxury trip which includes an FY18 business planning session and awards reception.
        </p>
        <p className='inside-p-in-p'>
        Jennifer Warawa, EVP of Partners, Accountants and Alliances at Sage said, “This is the year of Sage Business Cloud, and our business partners have been central to accelerating our vision of helping customers on their cloud journey. I have seen true dedication and commitment to the Sage brand throughout out the year, as well as an overwhelming customer obsession and sales excellence. I am very proud to have our business partners as part of our amazing extended partner team. I look forward to spending time planning further growth and celebrating with our top performing partners in Mexico.”
        </p>
        </p>
        
        </span>               
    </div>  

    <Divider style={{ border: '2px solid white', marginBottom: '5%' , boxShadow: '2px 5px 2px 0px black',}} />
                
                {/* PressRelease 5 */}

    <div>
        <h1 className='press-heading-one'>Bob Scott’s Insights Names the 2017 Top 100 VARs</h1>
        <h1 className='press-heading-two'>June 27, 2017</h1>
        <span  className='PressRelease-flex'>
        <p className='press-left'>
        <img className='press-Right-img' width={300} height={220} src={"https://www.adssglobal.net/wp-content/uploads/2017BobScottTop100.png"} alt='i am displayed' />
        ADSS Global has been named to the Bob Scott’s Insights Top 100 VARs for 2017. The Top 100 resellers are chosen from organizations specializing in the sale and implementation of Enterprise Resource Planning (ERP) and accounting software.
        <p className='inside-p-in-p'>
        “We want to congratulate this year’s class of Bob Scott’s Top 100 VARs,” said Bob Scott, executive editor of Bob Scott’s Insights. “This selection represents recognition of leaders in this important field.” The selection is based on annual revenue generated by each reseller. A special report that includes names of the organizations selected for this year’s Top 100 list, ranked by revenue, is downloadable at <Link target={'_blank'} to={"https://www.erpglobalinsights.com/"}> www.bobscottsinsights.com </Link>
        </p>
        <p className='inside-p-in-p'>
        This report is made possible by the continued support and cooperation of our readers and by our sponsors: Acumatica, Avalara and NetSuite.
         </p>
         <p className='inside-p-in-p'>
         Bob Scott has been informing and entertaining the mid-market financial software community via his email newsletters for 18 years. He has published this information via the Bob Scott’s Insights newsletter and website since 2009.
         </p>
         <p className='inside-p-in-p'>
         He has covered this market for nearly 26 years through print and electronic publications, first as technology editor of Accounting Today and then as the Editor of Accounting Technology from 1997 through 2009. He has covered the traditional tax and accounting profession during the same time and has continued to address that market as executive editor of the Progressive Accountant since 2009.
         </p>
        </p>
        
        </span>               
    </div>  

    <Divider style={{ border: '2px solid white', marginBottom: '5%' , boxShadow: '2px 5px 2px 0px black',}} />
                
                {/* PressRelease 6 */}

    <div>
        <h1 className='press-heading-one'>Bob Scott’s Insights Names the 2016 Top 100 VARs</h1>
        <h1 className='press-heading-two'>June 6, 2016</h1>
        <span  className='PressRelease-flex'>
        <p className='press-left'>
        <img className='press-Right-img' width={300} height={220} src={"https://www.adssglobal.net/wp-content/uploads/2016BobScottTop100.jpg"} alt='i am displayed' />
        ADSS Global has been named to the Bob Scott’s Insights Top 100 VARs for 2016. The Top 100 resellers are chosen from organizations specializing in the sale and implementation of Enterprise Resource Planning (ERP) and accounting software.
        <p className='inside-p-in-p'>
        “We want to congratulate this year’s class of Bob Scott’s Top 100 VARs,” said Bob Scott, executive editor of Bob Scott’s Insights. “This selection represents recognition of leaders in this important field.” The selection is based on annual revenue generated by each reseller. A special report that includes names of the organizations selected for this year’s Top 100 list, ranked by revenue, is downloadable at <Link target={'_blank'} to={"https://www.erpglobalinsights.com/"}> www.bobscottsinsights.com </Link>
        </p>
        <p className='inside-p-in-p'>
        This report is made possible by the continued support and cooperation of our readers and by our sponsors: Acumatica, Avalara, Intacct and NetSuite.</p>
         <p className='inside-p-in-p'>
         Bob Scott has been informing and entertaining the mid-market financial software community via his email newsletters for 17 years. He has published this information via the Bob Scott’s Insights newsletter and website since 2009.</p>
         <p className='inside-p-in-p'>
         He has covered this market for 25 years through print and electronic publications, first as technology editor of Accounting Today and then as the Editor of Accounting Technology from 1997 through 2009. He has covered the traditional tax and accounting profession during the same time and has continued to address that market as executive editor of the Progressive Accountant since 2009.
          </p>
        </p>
        
        </span>               
    </div> 
    <Divider style={{ border: '2px solid white', marginBottom: '5%' , boxShadow: '2px 5px 2px 0px black',}} />
                
                {/* PressRelease 7 */}

    <div>
        <h1 className='press-heading-one'>ADSS Global Named to the Sage North America President’s Circle for 2014-2015</h1>
        <h1 className='press-heading-two'>November 5, 2015</h1>
        <span  className='PressRelease-flex'>
        <p className='press-left'>
        ADSS Global, a Sage North America business partner specializing in the sales, installation, and support of Sage 300, Sage 100, Sage CRM, Sage HRMS, Sage Payment Solutions and Sage Fixed Assets, has been named to the Sage North America President’s Circle for the 2014-2015 business year. President’s Circle award winners are the top performing business partners for Sage products. Sage recognizes ADSS Global and all President’s Circle winners for their outstanding contributions in 2014-2015.
        <p className='inside-p-in-p'>
        The Sage North America President’s Circle is awarded to the leading performers among Sage business partners representing a host of segment-leading brands, including Sage 100, Sage 300, Sage X3, Sage construction and real estate products, Sage CRM, Sage HRMS (human resource management system), Sage Fixed Assets, and others.</p>
        <p className='inside-p-in-p'>
        “We recognize ADSS Global for their commitment to being customer-centric, which has deservedly earned them the Sage North America President’s Circle Award,” said Rich Spring, executive vice president and chief revenue officer for Sage North America. “Congratulations to the entire team at ADSS Global for this feat!”</p>
         <p className='inside-p-in-p'>
         Bob Scott has been informing and entertaining the mid-market financial software community via his email newsletters for 18 years. He has published this information via the Bob Scott’s Insights newsletter and website since 2009.
         </p>
         <p className='inside-p-in-p'>
         ADSS Global Achieves President’s Circle Business Partner Status for the Thirteenth Year!</p>
        </p>
        </span>               
    </div>   
               
    <Divider style={{ border: '2px solid white', marginBottom: '5%' , boxShadow: '2px 5px 2px 0px black',}} />
                
                {/* PressRelease 8 */}

    <div>
        <h1 className='press-heading-one'>ADSS Global Named a Sage Million Dollar Club achiever for 2014-2015</h1>
        <h1 className='press-heading-two'>November 5, 2015</h1>
        <span  className='PressRelease-flex'>
        <p className='press-left'>
        ADSS Global, a Sage North America business partner specializing in the sales, installation, and support of Sage 300, Sage 100, Sage CRM, Sage HRMS, Sage Payment Solutions and Sage Fixed Assets, has been named a Sage North America Million Dollar Club achiever for exceeding a million dollars in revenues during the 2014-2015 business year. Sage recognizes ADSS Global and all Million Dollar Club achievers for the substantial contributions made to the overall success of Sage, its business partners and the mutual customers they serve.
         </p>
         <p className='inside-p-in-p'>
          Million Dollar Club recognition is earned by Sage business partners representing a host of segment-leading brands, including Sage 100, Sage 300, Sage X3, Sage construction and real estate products, Sage CRM, Sage HRMS (human resource management system), Sage Fixed Assets and others.
      </p>
        <p className='inside-p-in-p'>
        “We are proud of our Sage business partner community for their role in effectively providing our customers with quality products and services,” said Rich Spring, executive vice president and chief revenue officer for Sage North America. “Congratulations to ADSS Global in recognition as a Sage Million Dollar Club achiever for 2014-2015!”
          </p>
        <p className='inside-p-in-p'>
        ADSS Global Achieves Million Dollar Club Status for the Tenth Consecutive Year!
          </p>
        </span>               
    </div>           

    
    <Divider style={{ border: '2px solid white', marginBottom: '5%' , boxShadow: '2px 5px 2px 0px black',}} />
                
                {/* PressRelease 9 */}

    <div>
        <h1 className='press-heading-one'>Bob Scott’s VAR Stars 2015 Announced</h1>
        <span  className='PressRelease-flex'>
        <p className='press-left'>
        <img className='press-Right-img' width={350} height={251} src={"https://www.adssglobal.net/wp-content/uploads/2015VARStarLogo.jpg"} alt='i am displayed' />
        ADSS Global, has been selected as a member of the Bob Scott’s VAR Stars for 2015, a group of 100 organizations honored for their accomplishments in the field of midmarket financial software
        
        <p className='inside-p-in-p'>
        Members of the VAR Stars were selected based on factors such growth, industry leadership and recognition, and innovation. Selection is not based on revenue and those firms chosen represent a wide range of size and many different software publishers of accounting software.
        </p>
        <p className='inside-p-in-p'>
        “Each year, 100 VAR Stars are picked from the best organizations that market financial software. It is always an honor to recognize those who contribute to the development of our business,” Bob Scott said.
         </p>
        </p>
        </span>               
    </div> 

     
    <Divider style={{ border: '2px solid white', marginBottom: '5%' , boxShadow: '2px 5px 2px 0px black',}} />
                
                {/* PressRelease 6 */}

    <div>
        <h1 className='press-heading-one'>Bob Scott’s Insights Names the 2015 Top 100 VARs</h1>
        <h1 className='press-heading-two'>June 16, 2015</h1>
        <span  className='PressRelease-flex'>
        <p className='press-left'>
        <img className='press-Right-img' width={300} height={220} src={"https://www.adssglobal.net/wp-content/uploads/2015BobScottTop100.png"} alt='i am displayed' />
        ADSS Global has been named to the Bob Scott’s Insights Top 100 VARs for 2015. The Top 100 resellers are chosen from organizations specializing in the sale and implementation of Enterprise Resource Planning (ERP) and accounting software.
        <p className='inside-p-in-p'>
        “We want to congratulate this year’s class of Bob Scott’s Top 100 VARs,” said Bob Scott, executive editor of Bob Scott’s Insights. The selection is based on annual revenue generated by each reseller. A special report that includes names of the organizations selected for this year’s Top 100 list, ranked by revenue, is downloadable at <Link target={'_blank'} to={"https://www.erpglobalinsights.com/"}> www.bobscottsinsights.com </Link>
        </p>
        <p className='inside-p-in-p'>
         Bob Scott has been informing and entertaining the mid-market financial software community via his email newsletters for 16 years. He has published this information via the Bob Scott’s Insights newsletter and website since 2009.
        </p>
        </p>
        </span>               
    </div> 
     
    <Divider style={{ border: '2px solid white', marginBottom: '5%' , boxShadow: '2px 5px 2px 0px black',}} />
                
                {/* PressRelease 9 */}

    <div>
        <h1 className='press-heading-one'>ADSS Global committed to Sage only solutions</h1>
        <h1 className='press-heading-two'>February 10, 2015</h1>
        <span  className='PressRelease-flex'>
        <p className='press-left'>
        ADSS Global, has decided to begin the new year focusing on Sage solutions only. Contrary to what other Sage Resellers might be doing by joining other vendors ERP Reseller programs, ADSS Global has instead officially retired NetSuite from our list of software solutions being offered after giving it a try for 2 ½ years. This is not a reflection on NetSuite or their software but at the end of the day the ADSS Global current and future client base requires Sage solutions which are a much better fit to offer our clients than NetSuite. So beginning in 2015 ADSS Global is 100% committed to Sage only solutions.</p>
         <p className='inside-p-in-p'>
         ADSS Global has been a top performing Sage Business Partner and Sage’s top Business Partner for the Sage 300 ERP product line for the last five (5) plus years. With the continued excellent Sage support and Reseller programs in place, ADSS Global believes we can still achieve that track record into the future by focusing all our efforts on Sage solutions only.
        </p>
        </span>               
    </div>           

     
    <Divider style={{ border: '2px solid white', marginBottom: '5%' , boxShadow: '2px 5px 2px 0px black',}} />
                
                {/* PressRelease 10 */}

    <div>
        <h1 className='press-heading-one'>ADSS Global Named to the Sage North America President’s Circle for 2013-2014</h1>
        <h1 className='press-heading-two'>August 15, 2014</h1>
        <span  className='PressRelease-flex'>
        <p className='press-left'>
        ADSS Global, a Sage North America business partner specializing in the sales, installation, and support of Sage 300 ERP, Sage 100 ERP, Sage CRM, Sage HRMS, Sage Payment Solutions and Sage Fixed Assets, has been named a Sage North America Million Dollar Club achiever for surpassing a million dollars in revenues during the 2013-2014 business year.
        </p>
        </span>               
    </div> 

    <Divider style={{ border: '2px solid white', marginBottom: '5%' , boxShadow: '2px 5px 2px 0px black',}} />
                
                {/* PressRelease 11 */}

    <div>
        <h1 className='press-heading-one'>Bob Scott’s VAR Stars 2012 Announced</h1>
        <span  className='PressRelease-flex'>
        <p className='press-left'>
        ADSS Global, has been selected as a member of the Bob Scott’s VAR Stars for 2012, a group of 100 organizations honored for their accomplishments in the field of midmarket financial software.
         </p>
        </span>               
    </div>

    <Divider style={{ border: '2px solid white', marginBottom: '5%' , boxShadow: '2px 5px 2px 0px black',}} />
                
                {/* PressRelease 13 */}

    <div>
        <h1 className='press-heading-one'>ADSS Global Named the Top Performer for 2012 for Sage 300 ERP</h1>
        <h1 className='press-heading-two'> November 15, 2012</h1>
        <span  className='PressRelease-flex'>
        <p className='press-left'>
        ADSS Global announced today it has been named the 2012 Top Performer for Sage 300 ERP. Sage Top Performer awards are presented to Sage North America business partners with the highest level of total product and service revenues by product line for fiscal year October 1, 2011 to September 30, 2012.
        </p>
        </span>               
    </div>


    <Divider style={{ border: '2px solid white', marginBottom: '5%' , boxShadow: '2px 5px 2px 0px black',}} />
                
                {/* PressRelease 14 */}

    <div>
        <h1 className='press-heading-one'>ADSS Global Named to Sage North America Chairman’s Club</h1>
        <h1 className='press-heading-two'> November 15, 2012</h1>
        <span  className='PressRelease-flex'>
        <p className='press-left'>
        ADSS Global, a Sage North America business partner specializing in the sales, installation, and support of Sage 300 ERP, Sage 100 ERP, Sage 100 Fund Accounting, Sage CRM, Sage HRMS, Sage Payment Solutions and Sage Fixed Assets, has been named to the Chairman’s Club based on their outstanding performance during the 2012 business year.
        </p>
        </span>               
    </div>

    <Divider style={{ border: '2px solid white', marginBottom: '5%' , boxShadow: '2px 5px 2px 0px black',}} />
                
                {/* PressRelease 14 */}

    <div>
        <h1 className='press-heading-one'>ADSS Global Named to Sage North America President’s Circle for 2012</h1>
        <h1 className='press-heading-two'> November 15, 2012</h1>
        <span  className='PressRelease-flex'>
        <p className='press-left'>
        ADSS Global, a Sage North America business partner specializing in the sales, installation, and support of Sage 300 ERP, Sage 100 ERP, Sage 100 Fund Accounting, Sage CRM, Sage HRMS, Sage Payment Solutions and Sage Fixed Assets, has been named to the Sage North America President’s Circle for the 2012 business year.
        </p>
        </span>               
    </div>

    <Divider style={{ border: '2px solid white', marginBottom: '5%' , boxShadow: '2px 5px 2px 0px black',}} />
                
                {/* PressRelease 15 */}

    <div>
        <h1 className='press-heading-one'>ADSS Global Named a Sage Million Dollar Club achiever for 2012</h1>
        <h1 className='press-heading-two'> November 15, 2012</h1>
        <span  className='PressRelease-flex'>
        <p className='press-left'>
        ADSS Global, a Sage North America business partner specializing in the sales, installation, and support of Sage 300 ERP, Sage 100 ERP, Sage 100 Fund Accounting, Sage CRM, Sage HRMS, Sage Payment Solutions and Sage Fixed Assets, has been named a Sage North America Million Dollar Club achiever for surpassing a million dollars in revenues during the 2012 business year.
        </p>
        </span>               
    </div>

    <Divider style={{ border: '2px solid white', marginBottom: '5%' , boxShadow: '2px 5px 2px 0px black',}} />
                
                {/* PressRelease 16 */}

    <div>
        <h1 className='press-heading-one'>ADSS Global recognized by Accounting Today in its 2012 VAR 100 Annual Ranking</h1>
        <h1 className='press-heading-two'> July 25, 2012 </h1>
        <span  className='PressRelease-flex'>
        <p className='press-left'>
        Sage business partner ADSS Global is ranked 14 out of the 100 top value added resellers in North America by Accounting Today magazine.
        </p>
        </span>               
    </div>

    <Divider style={{ border: '2px solid white', marginBottom: '5%' , boxShadow: '2px 5px 2px 0px black',}} />
                
                {/* PressRelease 17 */}

    <div>
        <h1 className='press-heading-one'>Bob Scott’s Insights Names the 2012 Top 100 VARs</h1>
        <h1 className='press-heading-two'> June 4, 2012 </h1>
        <span  className='PressRelease-flex'>
        <p className='press-left'>
        ADSS Global has been named to the Bob Scott’s Insights Top 100 VARs for 2012. The Top 100 are chosen from organizations specializing in the sale and implementation of Enterprise Resource Planning and accounting software.
       </p>
        </span>               
    </div>
      
    <Divider style={{ border: '2px solid white', marginBottom: '5%' , boxShadow: '2px 5px 2px 0px black',}} />
                
                {/* PressRelease 17 */}

    <div>
        <h1 className='press-heading-one'>ADSS Global Named a Sage North America Top Five Business Partner for 2011</h1>
        <h1 className='press-heading-two'> December 27, 2011 </h1>
        <span  className='PressRelease-flex'>
        <p className='press-left'>
        ADSS Global, a Sage North America business partner, has been named a Sage North America Top Five Business Partner for top sales revenues for the 2011 business year.
        </p>
        </span>               
    </div>

    <Divider style={{ border: '2px solid white', marginBottom: '5%' , boxShadow: '2px 5px 2px 0px black',}} />
                
                {/* PressRelease 17 */}

    <div>
        <h1 className='press-heading-one'>ADSS Global Named the Top Performer for 2011 for Sage 300 ERP</h1>
        <h1 className='press-heading-two'> December 27, 2011 </h1>
        <span  className='PressRelease-flex'>
        <p className='press-left'>
        ADSS Global is proud to achieve Sage 300 ERP (formerly Sage ERP Accpac) Top Performer for North America for the Third Consecutive Year!
        </p>
        </span>               
    </div>

    <Divider style={{ border: '2px solid white', marginBottom: '5%' , boxShadow: '2px 5px 2px 0px black',}} />
                
                {/* PressRelease 17 */}

    <div>
        <h1 className='press-heading-one'>ADSS Global Named to Sage North America Chairman’s Club and President’s Circle for 2011</h1>
        <h1 className='press-heading-two'> December 27, 2011 </h1>
        <span  className='PressRelease-flex'>
        <p className='press-left'>
        ADSS Global Achieves Chairman’s Club Status for Fourth Consecutive Year and Makes the President’s Circle for the Tenth Time!
       </p>
        </span>               
    </div>

    <Divider style={{ border: '2px solid white', marginBottom: '5%' , boxShadow: '2px 5px 2px 0px black',}} />
                
                {/* PressRelease 17 */}

    <div>
        <h1 className='press-heading-one'>ADSS Global Named a Sage Million Dollar Club achiever for 2011</h1>
        <h1 className='press-heading-two'> December 27, 2011 </h1>
        <span  className='PressRelease-flex'>
        <p className='press-left'>
        ADSS Global Achieves Million Dollar Club Status for Seventh Consecutive Year!
      </p>
        </span>               
    </div>

    <Divider style={{ border: '2px solid white', marginBottom: '5%' , boxShadow: '2px 5px 2px 0px black',}} />
                
                {/* PressRelease 17 */}

    <div>
        <h1 className='press-heading-one'> Bob Scott’s Insights Names the 2011 VAR Stars </h1>
        <h1 className='press-heading-two'> December 9, 2011 </h1>
        <span  className='PressRelease-flex'>
        <p className='press-left'>
        Progressive Media Group’s Bob Scott’s Insights has selected ADSS Global as a winner of its 2011 VAR Stars award. One hundred resellers are selected each year for this honor.

</p>
        </span>               
    </div>


    <Divider style={{ border: '2px solid white', marginBottom: '5%' , boxShadow: '2px 5px 2px 0px black',}} />
                
                {/* PressRelease 17 */}

    <div>
        <h1 className='press-heading-one'> ADSS Global Named the Top Performer for 2010 for Sage 300 ERP (Accpac) </h1>
        <h1 className='press-heading-two'> November 9, 2010 </h1>
        <span  className='PressRelease-flex'>
        <p className='press-left'>
        ADSS Global is proud to achieve Sage 300 ERP (formerly Sage ERP Accpac) Top Performer for North America for the Second Consecutive Year.
       </p>
        </span>               
    </div>

    <Divider style={{ border: '2px solid white', marginBottom: '5%' , boxShadow: '2px 5px 2px 0px black',}} />
                
                {/* PressRelease 17 */}

    <div>
        <h1 className='press-heading-one'> ADSS Global Named to Sage North America Chairman’s Club and President’s Circle for 2010 </h1>
        <h1 className='press-heading-two'> November 9, 2010 </h1>
        <span  className='PressRelease-flex'>
        <p className='press-left'>
        ADSS Global Achieves Chairman’s Club Status for the Third Consecutive Year and Makes the President’s Circle for the Ninth Consecutive Year.
        </p>
        </span>               
    </div>
            

    <Divider style={{ border: '2px solid white', marginBottom: '5%' , boxShadow: '2px 5px 2px 0px black',}} />
                
                {/* PressRelease 17 */}

    <div>
        <h1 className='press-heading-one'> ADSS Global Named a Sage Million Dollar Club achiever for 2010 </h1>
        <h1 className='press-heading-two'> November 9, 2010 </h1>
        <span  className='PressRelease-flex'>
        <p className='press-left'>
        ADSS Global Achieves Million Dollar Club Status for the Sixth Consecutive Year.
        </p>
        </span>               
    </div>

    <Divider style={{ border: '2px solid white', marginBottom: '5%' , boxShadow: '2px 5px 2px 0px black',}} />
                
                {/* PressRelease 17 */}

    <div>
        <h1 className='press-heading-one'> ADSS Global adds the Award Winning Sage 100 ERP Software Product Offering </h1>
        <h1 className='press-heading-two'> February 28, 2010 </h1>
        <span  className='PressRelease-flex'>
        <p className='press-left'>
        ADSS Global, announced today that it is adding Sage 100 ERP (formerly Sage ERP MAS 90 and 200) to the ADSS Global Sage Software product offerings. According to Robert J. Campbell, President of ADSS Global, “this will allow ADSS to expand into other businesses better suited for these award winning software solutions”.
       </p>
        </span>               
    </div>

    <Divider style={{ border: '2px solid white', marginBottom: '5%' , boxShadow: '2px 5px 2px 0px black',}} />
                
                {/* PressRelease 17 */}

    <div>
        <h1 className='press-heading-one'> ADSS Global Named to Sage North America Chairman’s Club and President’s Circle for 2009 </h1>
        <h1 className='press-heading-two'> November 3, 2009 </h1>
        <span  className='PressRelease-flex'>
        <p className='press-left'>
        ADSS Global Achieves Chairman’s Club Status for the Second Consecutive Year and Makes the President’s Circle for the 8th Time In a Row.
      </p>
        </span>               
    </div>

    <Divider style={{ border: '2px solid white', marginBottom: '5%' , boxShadow: '2px 5px 2px 0px black',}} />
                
                {/* PressRelease 17 */}

    <div>
        <h1 className='press-heading-one'> ADSS Global Named to Sage Million Dollar Club for 2009 </h1>
        <h1 className='press-heading-two'> November 3, 2009 </h1>
        <span  className='PressRelease-flex'>
        <p className='press-left'>
        ADSS Global, a Sage North America business partner, has been named to the Sage North America Million Dollar Club for eclipsing a million dollars in revenues the past fiscal year and for the fifth consecutive.
        </p>
        </span>               
    </div>

    <Divider style={{ border: '2px solid white', marginBottom: '5%' , boxShadow: '2px 5px 2px 0px black',}} />
                
                {/* PressRelease 17 */}

    <div>
        <h1 className='press-heading-one'> ADSS Global Ranks Number 20 In 2009 VAR 100 Listing </h1>
        <h1 className='press-heading-two'> April 21, 2009 </h1>
        <span  className='PressRelease-flex'>
        <p className='press-left'>
        Sage business partner ADSS Global is ranked 20 out of the 100 top value added resellers in the U.S. by Accounting Technology magazine.
        </p>
        </span>               
    </div>

    <Divider style={{ border: '2px solid white', marginBottom: '5%' , boxShadow: '2px 5px 2px 0px black',}} />
                
                {/* PressRelease 17 */}

    <div>
        <h1 className='press-heading-one'> ADSS Global earns Sage Chairman’s Club </h1>
        <h1 className='press-heading-two'> February 15, 2008 </h1>
        <span  className='PressRelease-flex'>
        <p className='press-left'>
        Sage Software announced today its Chairman’s Club members, representing the elite of the Sage Software Business Partner community. Members were determined based on their performance during the company’s 2007 fiscal year.
       </p>
        </span>               
    </div>

    
    <Divider style={{ border: '2px solid white', marginBottom: '5%' , boxShadow: '2px 5px 2px 0px black',}} />
                
                {/* PressRelease 17 */}

    <div>
        <h1 className='press-heading-one'> ADSS Global recognized as a Top 100 Firm </h1>
        <h1 className='press-heading-two'> December 15, 2007
 </h1>
        <span  className='PressRelease-flex'>
        <p className='press-left'>
        ADSS Global has been recognized as a Top 100 Firm by Accounting Technology Magazine for 2007. Accounting Technology and Accounting Today Magazine has again recognized our organization as one of the top 100 accounting systems resellers in North America. ADSS has grown from just an Accounting systems reseller to a full end to end solutions provider offering such systems as CRM, HR, Warehouse Management and eCommerce.
        </p>
        </span>               
    </div>

    <Divider style={{ border: '2px solid white', marginBottom: '5%' , boxShadow: '2px 5px 2px 0px black',}} />
                
                {/* PressRelease 17 */}

    <div>
        <h1 className='press-heading-one'> ADSS Global become part of Sage’s Million Dollar Circle </h1>
        <h1 className='press-heading-two'> November 12, 2007 </h1>
        <span  className='PressRelease-flex'>
        <p className='press-left'>
        ADSS Global become part of Sage’s Million Dollar Circle. To be a part of this group our organization had to generate over a million dollars in revenue for Sage in their fiscal year. This comprised of sales and software assurance revenue. “We are proud to be part of this elite group” said owner Peter Kaufman. “Our organization has been selling and supporting Sage 300 ERP (formerly Sage ERP Accpac) for 17 years and has grown our client base to over 900 Sage 300 ERP (formerly Sage ERP Accpac) customers.”
       </p>
        </span>               
    </div>
    
    <Divider style={{ border: '2px solid white', marginBottom: '5%' , boxShadow: '2px 5px 2px 0px black',}} />
                
                {/* PressRelease 17 */}

    <div className='last'>
        <h1 className='press-heading-one'> ADSS Global earns Sage President’s Circle in 2007 </h1>
        <h1 className='press-heading-two'> November 11, 2007 </h1>
        <span  className='PressRelease-flex'>
        <p className='press-left'>
        For the seventh consecutive year our organization has earned the President’s Circle award from Sage as the top reseller of Sage 300 ERP (formerly Sage ERP Accpac) in the United States and 3rd in all of North America. It is an honor to be part of this elite group.
   </p>
        </span>               
    </div>
    

            </diV>
            </div>
            
        </div>
    )
}

export default PressRelease