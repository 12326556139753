import { Button, Col,  Row } from 'antd';
import React from 'react'
import "../styles/Sage300.css";
import "../styles/office.css";
import "../styles/AwardsAccomplishment.css";
import { Link } from 'react-router-dom';

export const AwardAccomplishments = () => {
  return (
    <div> 
      <Row className="sage300-page-1 hero-div overlay" role="banner">
        <div className="sage-custom-header">
          <img
            src={"https://673dc7.a2cdn1.secureserver.net/wp-content/uploads/2017/12/shaking-hands.jpg"}
            width="1920"
            height="1080"
            alt=""
          />
        </div>
        <div>
            <Col xs={24} sm={12} className="sage-center">
            <h1>ADSS Global Offices</h1>
            <h2>Call Us At: 613-221-5950</h2>
            </Col>
          </div>
        </Row>

        {/* Middle Section of Office */}

      <div className='award-main'>
        <div className='warp'>
           <p>
            <img className='img-right' src='https://673dc7.a2cdn1.secureserver.net/wp-content/uploads/Sage-CEO-Circle-768x620.png' alt='' />
              As ranked by Sage, ADSS Global has been awarded with the following:
           </p>
           <ul>
                        <li>#1 Sage 300 Cloud Business Partner in the World as Ranked by Sage</li>
                        <li>Top 10 Overall Reseller for Sage</li>
                        <li>Sage Diamond Partner</li>
                        <li>Sage Select Partner</li>
                        <li>Sage CEO Circle Member</li>
                        <li>Microsoft Gold Partner</li>
                        <li>Citrix Silver Certified Partner</li>
                      </ul>
        <p>ADSS Global is represented by a staff of highly trained certified consultants, all of whom are CPA’s with diversified industry experience along with a staff of network engineers and programmers that all work together in a team approach.</p>
        <p>At ADSS Global, we offer support and training on <Link to={"/software/sage300"}>Sage 300cloud,</Link> <Link to={"/software/sage100"}>Sage 100cloud</Link>  (formerly Sage Live) and the End-To-End Business Solutions including Sage HRMS, Sage People, Sage CRM, Document Management and AP Workflow, eCommerce, Warehouse Management, Project & Job Costing, POS, Sage Fixed Assets and EDI.</p>
        <p>ADSS Global is proud to have a diverse client base that represents many different industries ranging in size from small business organizations that cover the nonprofit sector to publicly-held companies.</p>
        
        <div className='img-warp'>
          <img alt='' src='https://www.adssglobal.net/wp-content/uploads/Sage_CEOCircle_Winner-resize.png'/>
          <img alt='' src='https://673dc7.a2cdn1.secureserver.net/wp-content/uploads/Sage-ceo-logo-2018_25-300x243.png'/>
          <img alt='' src='https://673dc7.a2cdn1.secureserver.net/wp-content/uploads/VAR-Stars-2018-Logo-300x220.jpg'/>
          <img alt='' src='https://673dc7.a2cdn1.secureserver.net/wp-content/uploads/2017BobScottTop100-300x209.png'/>
          <img alt='' src='https://673dc7.a2cdn1.secureserver.net/wp-content/uploads/Sage-Diamond-Partner-300x67.png'/>
        </div>
        </div>

      </div>
                
       

        {/* LAst Section  */}
        <div className='sage-last-section'>
        <Row className="sage300-page-1 hero-div overlay" role="banner">
        <div className="sage-custom-header">
          <img
            src={"https://673dc7.a2cdn1.secureserver.net/wp-content/uploads/2017/12/shaking-hands.jpg"}
            width="1920"
            height="1080"
            alt=""
          />
        </div>
        <div>
        <Col xs={24} sm={12} className="help-text">
            <h1>How Can We Help?</h1>
            <h3>224 Cairnsmore Circle</h3>
            <h3>Ottawa ON K2J 0G5</h3>
            <h3>613-221-5950</h3>
            <h3> <a href="http://adsshelp.com/"> <em>Or click below to leave a message and one of our consultants will follow up.</em>
           <br/> <Button className="help-btn"   type={"primary"} onClick={(e)=>{
            
           }}>Get In Touch</Button><br/>
            </a></h3>
            </Col>
          </div>
        </Row>
        </div>
      </div>
   
  )
  }