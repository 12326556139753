import { Button, Col, Row } from 'antd';
import React from 'react'
import { Link } from 'react-router-dom';
import "../styles/Sage300.css";
import "../styles/SageIntact.css";
import RightSection from './RightSection';
export const SageHRMS = () => {
  return (
    <div>
       <Row className="sage300-page-1 hero-div overlay" role="banner">
        <div className="sage-custom-header">
          <img
            src={"https://673dc7.a2cdn1.secureserver.net/wp-content/uploads/2017/10/sage-300-screenshot.png"}
            width="1920"
            height="1080"
            alt=""
          />
        </div>
        <div>
            <Col xs={24} sm={12} className="sage-center">
            <h1>Sage HRMS and Payroll</h1>
            <h2>Human Resource Management & Payroll Processing</h2>
            </Col>
          </div>
        </Row>
        <div className='sage-div'>
          <div className='sage-left'>
          <h1>Transforming HR From Administrative Burden to Strategic Advantage</h1>
          <p>
          Human resources today is a lot more than recruiting, hiring, maintaining employee 
          records, and processing payroll. It’s about providing crucial process and decision
           support for the rest of the organization. Now there is a set of powerful software 
           solutions designed to help small to medium businesses excel at employee management.
           <Link to={"/software/sage-hrms-payroll/"}>Sage HRMS</Link>  (formerly Sage Abra HR) and Payroll systems will elevate HR department
           from an administrative role to a strategic player in forwarding business growth.
          </p>
          
          <h2>A Complete HR Management and Payroll Solution</h2>
         <p>
         From managing employees and meeting state and federal reporting obligations to 
         complying with periodic tax reporting requirements, Sage HRMS helps streamline
          day-to-day administrative tasks and provides insights that will help you manage, forecast, 
         and implement effective strategies to make positive changes to your bottom line
         </p>
             <div>
             <p>
            <ul>
            <li>Complete array of HR management & payroll solutions</li>
            <li>Built on experience</li>
            <li>Tested and developed by HR pros</li>
            <li>Enhanced service, efficiency, and productivity</li>
            <li>Your entire organization benefits with improved service</li>
            </ul>
         </p>
        <div>
          <h2>Sage HRMS</h2>
          <p>
            Sage HRMS is designed for those companies with human resources
             (HR) departments that may be struggling to be more efficient. 
             Sage HRMS reduces time consuming clerical work by centralizing,
              tracking, and reporting employee data that’s scattered across multiple systems.
               Ensure government compliance and avoid company risk by meeting federal and state rules, regulations, and reporting requirements. Sage HRMS provides powerful tools for managing your employee information.
          </p>
          <h2>Sage Employee Self Service (ESS)</h2>
          <p>
          Sage ESS ensures you have accurate information pertaining to benefits, 
          attendance, payroll, and training by connecting employees, supervisors, managers,
           and administrators across the company through employee self-service capabilities 
           maintained through a web portal. Sage ESS securely unlocks meaningful workforce 
           information that you can use to make better decisions. Our seamless technology
            solution eliminates error-prone manual processes, too. The result? You save time, 
          increase productivity, and assure an excellent return on investment.
          </p>
          <h2>Sage HRMS Payroll</h2>
          <p>
          Sage HRMS Payroll improves human resources and payroll efficiency and empowers the 
          HR department to proactively support company objectives. Integrate and streamline 
          your HR, compliance, benefits, and payroll processes, make reporting easy and 
          insightful, and improve the 
          effectiveness of employee training and retention activities with Sage HRMS Payroll.
          </p>
        </div>

           <div>
            <Button className='demo-btn' type='primary'><Link>REQUEST A DEMO »</Link></Button>
           </div>
           <hr />
           <div className='sage-left-img'>
            <img width={700} height={550} alt="Sage HRMS & Payroll" src={'https://673dc7.a2cdn1.secureserver.net/wp-content/uploads/sage-hrms-screen.png'} />
           </div>
           </div>
          </div>
          {/* Right Section  */}
          <div className='sage-right'>
          <img src={"https://673dc7.a2cdn1.secureserver.net/wp-content/uploads/2017/11/sage-hrms-employee-screen.jpg?time=1682358492"}  alt='SageCRM'/>
          <div>
            <RightSection />
          </div>
          </div>
          
        </div>
        <div className='sage-last-section'>
        <Row className="sage300-page-1 hero-div overlay" role="banner">
        <div className="sage-custom-header">
          <img
            src={"https://673dc7.a2cdn1.secureserver.net/wp-content/uploads/2017/10/sage-300-screenshot.png"}
            width="1920"
            height="1080"
            alt=""
          />
        </div>
        <div>
        <Col xs={24} sm={12} className="help-text">
            <h1>How Can We Help?</h1>
            <h3>224 Cairnsmore Circle</h3>
            <h3>Ottawa ON K2J 0G5</h3>
            <h3>613-221-5950</h3>
            <h3> <a href="http://adsshelp.com/"> <em>Or click below to leave a message and one of our consultants will follow up.</em>
           <br/> <Button className="help-btn"   type={"primary"} onClick={(e)=>{
            
           }}>Get In Touch</Button><br/>
            </a></h3>
            </Col>
          </div>
        </Row>
        </div>
      </div>
   
  )
  }