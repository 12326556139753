import { Button, Col,  Row } from 'antd';
import React from 'react'
import "../styles/Sage300.css";
import "../styles/office.css";
import "../styles/AwardsAccomplishment.css";
import "../styles/Contactsupport.css";

export const ContactSupport = () => {
  return (
    <div>  
      <Row className="sage300-page-1 hero-div overlay" role="banner">
        <div className="sage-custom-header">
          <img
            src={"https://673dc7.a2cdn1.secureserver.net/wp-content/uploads/2017/12/shaking-hands.jpg"}
            width="1920"
            height="1080"
            alt=""
          />
        </div>
        <div>
            <Col xs={24} sm={12} className="sage-center">
            <h1>ADSS Global Offices</h1>
            <h2>Call Us At: 613-221-5950</h2>
            </Col>
          </div>
        </Row>

        {/* Middle Section of Office */}
       <div className='contact-support-main'>
        <div className='warp'>
          <h1 className='support-heading'>
          <img className='support-logo' src='https://673dc7.a2cdn1.secureserver.net/wp-content/uploads/2017/10/adss_global_logo.jpg' alt='' />
          <span> For Technical Support, please call our support desk at <span className='text-blue'>613-221-5950</span> .</span>
          </h1>
        </div>

       </div>



        {/* LAst Section  */}
        <div className='sage-last-section'>
        <Row className="sage300-page-1 hero-div overlay" role="banner">
        <div className="sage-custom-header">
          <img
            src={"https://673dc7.a2cdn1.secureserver.net/wp-content/uploads/2017/12/shaking-hands.jpg"}
            width="1920"
            height="1080"
            alt=""
          />
        </div>
        <div>
        <Col xs={24} sm={12} className="help-text">
            <h1>How Can We Help?</h1>
            <h3>224 Cairnsmore Circle</h3>
            <h3>Ottawa ON K2J 0G5</h3>
            <h3>613-221-5950</h3>
            <h3> <a href="http://adsshelp.com/"> <em>Or click below to leave a message and one of our consultants will follow up.</em>
           <br/> <Button className="help-btn"   type={"primary"} onClick={(e)=>{
            
           }}>Get In Touch</Button><br/>
            </a></h3>
            </Col>
          </div>
        </Row>
        </div>
      </div>
   
  )
  }