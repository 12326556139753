import React from "react";
import "../styles/Home.css";
import { Row, Col,  } from "antd";
import { LaptopOutlined, UserOutlined, ToolOutlined } from "@ant-design/icons";
import { Carousel } from "antd";


export const Home = () => {
  return (
    <div>
      <Row className="front-page-1 hero-div overlay" role="banner">
        <div className="wp-custom-header">
          <img
            src={`${process.env.PUBLIC_URL}/src/hero.jpg`}
            width="1920"
            height="1080"
            alt=""
          />
        </div>
        <div className="wrap">
          <div className="widget_text widget widget_custom_html">
            <div className="widget_text widget-wrap">
              <div className="textwidget custom-html-widget">
                <Col xs={24} sm={12} className="hero-left">
                  <h1>ADSS Global</h1>
                  <h2>Sage Partner &amp; Technology Provider</h2>
                  <p>
                    Supporting Over 3,750 Customers in More Than 20 Countries
                    Since 1981
                  </p>
                  <a href="/software" className="button">
                    What We Do »
                  </a>
                </Col>
                <Col xs={24} sm={12} className="hero-right">
                  <img
                    src={`${process.env.PUBLIC_URL}/src/sage-ceo-2017-winner.png`}
                    alt="Sage CEO Circle Winner"
                  />
                </Col>
              </div>
            </div>
          </div>
        </div>
      </Row>

      <Row
        className="front-page-2"
        gutter={[34, 34]}
        justify={"center"}
        align={"middle"}
      >
        <Col xs={24} md={20} lg={6} xl={8}>
          <div className="item">
            <div className="icon">
              <LaptopOutlined />
            </div>
            <h6>Software</h6>
            <p>
              We sell, implement and support accounting, ERP, CRM, and HRMS
              software
            </p>
            <a href="/software" className="button">
              Software »
            </a>
          </div>
        </Col>
        <Col xs={24} md={20} lg={6} xl={8}>
          <div className="item">
            <div className="icon">
              <ToolOutlined />
            </div>
            <h6>IT Support</h6>
            <p>
              Everything from hosting and managed services to network solutions
              and disaster recovery.
            </p>
            <a href="/software" className="button">
              IT Support »
            </a>
          </div>
        </Col>
        <Col xs={24} md={20} lg={6} xl={8}>
          <div className="item">
            <div className="icon">
              <UserOutlined />
            </div>
            <h6>Our Customers</h6>
            <p>
              Learn what our customers have to say about working with ADSS
              Global
            </p>
            <a href="/software" className="button">
              Our Customers »
            </a>
          </div>
        </Col>
      </Row>
      <Row className="front-page-3 testimonial">
        <Col>
          <h1 className="testimonial-header">What Our Customers Say …</h1>
        </Col>
        <Col>
          <Carousel
            centerMode={true}
            centerPadding="50px"
            dotPosition="none"
            slidesPerRow={1}
            className="slider"
          >
            <div style={{ marginLeft: 'auto', marginRight: 'auto' }}>
            <div className="card">
              <div className="icon-container">
                <img
                  src="https://673dc7.a2cdn1.secureserver.net/wp-content/uploads/2017/04/testimonial-icon.png?time=1680798921"
                  alt="Testimonial Icon"
                  className="icon-testmonial"
                />
              </div>
              <div className="card-body">
                <blockquote className="blockquote mb-0">
                  <p>
                    "The Algonquin Students’ Association has been with Marc and
                    ADSS for over 10 years. During that time Marc has
                    transitioned the organization from manual processes to the
                    world of electronic payments and automated processes. Asides
                    from Marc’s knowledge of the industry and required
                    automations, he and ADSS are reliable providers who respond
                    quickly to our requests and needs."
                  </p>
                  <footer className="blockquote-footer">
                    <em>  Stafford C. Rollocks{" "}</em>
                    <br/>
                    <cite title="Source Title">
                      Senior Manager & <em>Finance and Administration</em> 
                    </cite>
                  </footer>
                </blockquote>
              </div>
              
            </div>
            </div>
          
          </Carousel>
        </Col>
      </Row>
      <Row className="front-page-5 hero-div overlay" role="banner">
        <div className="wp-custom-header">
          <img
            src={`${process.env.PUBLIC_URL}/src/hero.jpg`}
            width="1920"
            height="1080"
            alt="ADSS Global - Sage Software Partner &amp; Technology Provider"
          />
        </div>
        <Col>
          <div className="wrap page5body">
            <h2 className="header">
              <span>How Can We Help?</span>
            </h2>
            <h2>
              224 Cairnsmore Circle <br /> Ottawa ON K2J 0G5
              <br />
              613-221-5950
              <br />
              <br></br>
              <a href="/" style={{ color: "white" }}>
                Or click below to leave a message and one of our consultants
                will follow up.
              </a>
            </h2>
            <br />
            <a href="/company/contact/" className="button">
              Get In Touch
            </a>
          </div>
        </Col>
      </Row>
      <Row>
        <div className="front-page-6 widget-area">
          <div className="wrap">
            <section id="media_image-3" className="widget widget_media_image">
              <div className="widget-wrap">
                <img
                  width="200"
                  height="80"
                  src="https://673dc7.a2cdn1.secureserver.net/wp-content/uploads/2017/10/adss_global_logo.jpg?time=1680798921"
                  className="image wp-image-1225  attachment-full size-full"
                  alt=""
                  decoding="async"
                  loading="lazy"
                />
              </div>
            </section>
            <section
              id="custom_html-12"
              className="widget_text widget widget_custom_html"
            >
              <div className="widget_text widget-wrap">
                <div className="bodytext">
                  ADSS has been providing automated solutions and IT Technology
                  for thousands of businesses
                  <br /> of which some of our current clients have been with
                  ADSS since the 1980s. We are here to <br />
                  provide the solutions you seek for the best value possible.
                  <br />
                  <br />
                  <h4>ADSS Customer Support Portal</h4>
                  <div className="first one-half">
                    <a href="http://adsshelp.com" target="_blank" rel="noreferrer">
                      <img
                      className="supportImg"
                        alt="Support Button"
                        src={`${process.env.PUBLIC_URL}/src/support-button.png`}
                      />
                    </a>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </Row>
    </div>
  );
};
