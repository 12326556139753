import React from 'react'
import { Link } from 'react-router-dom';
import "../styles/RightSection.css";


const RightSection = () => {
  return (
    <div>
      <div className='sage-right-list'>
          <h1>Software and IT Support</h1></div>
          <ul>
          <li><Link to={"/software/sage300"}>Sage 300</Link></li>
           <hr />
           <li><Link to={"/software/sage100"}>Sage 100</Link></li>
           <hr />
           <li><Link to={"/software/sage-crm"}>Sage CRM</Link></li>
           <hr />
           <li><Link to={"/software/sage-hrms-payroll"}>Sage HRMS</Link></li>
           <hr />
           <li><Link to={"/services/hosting"}>Hosting</Link></li>
           <hr />
           <li><Link to={"/services/managed-services/"}>Managed Services</Link></li>
           <hr />
           <li><Link to={"/services/network/"}>Network Solutions</Link></li>
           <hr />
          </ul>
          

    </div>
  )
}

export default RightSection
