import React from 'react'
import { Button, Col, Divider, Row } from 'antd';
import ReactPlayer from 'react-player'
import "../styles/Sage300.css";
import "../styles/TrainingVideos.css";
export const TrainingVideos = () => {
  const videoUrlsSage300Training = [
    // Sage 300 Training
    {
      caption:"Reversing G/L Entries",
      url: process.env.PUBLIC_URL + '/videos/ReversingGLEntries.mp4',
    },
    {
      caption:"1099 and CPRS Reporting",
      url: process.env.PUBLIC_URL + '/videos/1099CPRS&Reporting.mp4',
    },
    {
      caption:"A/R Write Off Methods",
      url: process.env.PUBLIC_URL + '/videos/ARwriteMethod.mp4',
    },
    {
      caption:"Bank Transaction Inquiry",
      url: process.env.PUBLIC_URL + '/videos/Bank-transiction.mp4',
    },
    {
      caption:"Bank Transfers",
      url: process.env.PUBLIC_URL + '/videos/Bank-transfer.mp4',
    },
    {
      caption:"Bills of Material",
      url: process.env.PUBLIC_URL + '/videos/Bills-of-Material.mp4',
    },
    {
      caption:"Customer Comments",
      url: process.env.PUBLIC_URL + '/videos/Customer-Comments.mp4',
    },
    {
      caption:"Exporting Data to Excel",
      url: process.env.PUBLIC_URL + '/videos/Exporting-Data-to-Excel.mp4',
    },
    {
      caption:"General Ledger Drilldown",
      url: process.env.PUBLIC_URL + '/videos/General-Ledger-Drilldown.mp4',
    },  
    {
      caption:"Intro to Sage Intelligence Report Designer",
      url: process.env.PUBLIC_URL + '/videos/Intelligence-report.mp4',
    },
    {
      caption:"Inventory Quantities, Costs, and Day End Processing",
      url: process.env.PUBLIC_URL + '/videos/Inventory-quantities.mp4',
    },  
    {
      caption:"Import Mass Updates Into Number Changers",
      url: process.env.PUBLIC_URL + '/videos/Number-Change.mp4',
    },
    {
      caption:"Miscellaneous Receipts",
      url: process.env.PUBLIC_URL + '/videos/Misc-Receipts.mp4',
    },  
    {
      caption:"Setting Up Multicurrency",
      url: process.env.PUBLIC_URL + '/videos/Multi-Currency.mp4',
    },
    {
      caption:"Inventory Item Wizard",
      url: process.env.PUBLIC_URL + '/videos/Item-Wizard.mp4',
    },  
    {
      caption:"Optional Fields",
      url: process.env.PUBLIC_URL + '/videos/Optional-Fields.mp4',
    },
    {
      caption:"PO Invoicing Flexibility",
      url: process.env.PUBLIC_URL + '/videos/Invoicing-Flexibility.mp4',
    },  
    {
      caption:"Portal Inquiry Tool",
      url: process.env.PUBLIC_URL + '/videos/Portal-Inquiry.mp4',
    },
    {
      caption:"Portal Interface & Shortcuts",
      url: process.env.PUBLIC_URL + '/videos/Portal-Shortcuts.mp4',
    },  
    {
      caption:"Purchase Order Requisitions",
      url: process.env.PUBLIC_URL + '/videos/Purchase-Order.mp4',
    },
    {
      caption:"Submodule Period Locking",
      url: process.env.PUBLIC_URL + '/videos/Submodule-Period-Locking.mp4',
    },  
  
  ];

  const videoUrlsSageCRMTraining = [
    {
      caption:"Field Security",
      url: process.env.PUBLIC_URL + '/videos/Field-Security.mp4',
      
    },
    {
      caption:"Using Advanced Find",
      url: process.env.PUBLIC_URL + '/videos/Using-Advanced-Find.mp4',
    },
    {
      caption:"E-Marketing Campaign Setup",
      url: process.env.PUBLIC_URL + '/videos/EMarketing-Campaign-Setup.mp4',
    },
    {
      caption:"Importing Templates to E-Marketing",
      url: process.env.PUBLIC_URL + '/videos/emarketing-import-template.mp4',
    },
    {
      caption:"E-Marketing Template Merge Fields and Tracked Links",
      url: process.env.PUBLIC_URL + '/videos/emarketing-template-merge-fields.mp4',
    },
    {
      caption:"Add Email Fields to Person Records",
      url: process.env.PUBLIC_URL + '/videos/adding-email-fields.mp4',
    },
    {
      caption:"Interactive Dashboard Linked Gadgets",
      url: process.env.PUBLIC_URL + '/videos/interactive-dashboard-gadgets.mp4',
    },
    {
      caption:"Interactive Dashboard Flexible Layouts",
      url: process.env.PUBLIC_URL + '/videos/interactive-dashboard-flexible-layout.mp4',
    },
    {
      caption:"Enhanced Quotes, Orders, & ERP Integration",
      url: process.env.PUBLIC_URL + '/videos/v7-quote-order-entry.mp4',
    },
    {
      caption:"Lead Management",
      url: process.env.PUBLIC_URL + '/videos/leads.mp4',
    },
    {
      caption:"List Reports",
      url: process.env.PUBLIC_URL + '/videos/List Reports.mp4',
    },
    {
      caption:"Notes Functionality",
      url: process.env.PUBLIC_URL + '/videos/notes.mp4',
    },
    {
      caption:"Print Address Labels & Envelopes",
      url: process.env.PUBLIC_URL + '/videos/printing-labels-and-envelopes.mp4',
    },
    {
      caption:"Search Select Advanced Fields",
      url: process.env.PUBLIC_URL + '/videos/search-select-advanced-field.mp4',
    },
    {
      caption:"Standalone Person Records",
      url: process.env.PUBLIC_URL + '/videos/standalone-person-records.mp4',
    },
    {
      caption:"Setting Up Timings",
      url: process.env.PUBLIC_URL + '/videos/timings.mp4',
    },
    {
      caption:"Lead Conversion",
      url: process.env.PUBLIC_URL + '/videos/lead-conversion.mp4',
    },

  ];


  return (
    <div> 
      <Row className="sage300-page-1 hero-div overlay" role="banner">
        <div className="sage-custom-header">
          <img
            src={"https://673dc7.a2cdn1.secureserver.net/wp-content/uploads/2017/12/shaking-hands.jpg"}
            width="1920"
            height="1080"
            alt=""
          />
        </div>
        <div>
            <Col xs={24} sm={12} className="sage-center">
            <h1>ADSS Global Offices</h1>
            <h2>Call Us At: 613-221-5950</h2>
            </Col>
          </div>
        </Row>
        {/* Middle Section of Office */}
        <div className='training-videos'> 
        <h1 className='training-heading'>Sage 300 Training</h1>
        <Divider style={{ border: '2px solid white', marginBottom:'5%', boxShadow: '2px 5px 2px 0px black', }} />
        <div className='display-player'>
      {videoUrlsSage300Training.map((item) => (
          <div className='' key={item}>
          <h3 className='video-heading'>{item.caption}</h3>
          <ReactPlayer    className='video-player' url={item.url}
                  light={"https://673dc7.a2cdn1.secureserver.net/wp-content/uploads/Sage-300-video-thumb-mod.png"}

          controls />
        </div>
      ))}

        </div>
        </div>
        <div className='training-videos'> 
        <h1 className='training-heading'>Sage CRM Training</h1>
        <Divider style={{ border: '2px solid white', marginBottom:'5%', boxShadow: '2px 5px 2px 0px black', }} />
        <div className='display-player'>
      {videoUrlsSageCRMTraining.map((items) => (
          <div className='' key={items}>
          <h3 className='video-heading'>{items.caption}</h3>
          <ReactPlayer    className='video-player' url={items.url} 
           light={"https://673dc7.a2cdn1.secureserver.net/wp-content/uploads/sage-crm-video-thumb.png"}
          controls />
        </div>
      ))}

        </div>
        </div>
       
        {/* Last Section */}
        <div className='sage-last-section'>
        <Row className="sage300-page-1 hero-div overlay" role="banner">
        <div className="sage-custom-header">
          <img
            src={"https://673dc7.a2cdn1.secureserver.net/wp-content/uploads/2017/12/shaking-hands.jpg"}
            width="1920"
            height="1080"
            alt=""
          />
        </div>
        <div>
        <Col xs={24} sm={12} className="help-text">
            <h1>How Can We Help?</h1>
            <h3>224 Cairnsmore Circle</h3>
            <h3>Ottawa ON K2J 0G5</h3>
            <h3>613-221-5950</h3>
            <h3> <a href="http://adsshelp.com/"> <em>Or click below to leave a message and one of our consultants will follow up.</em>
           <br/> <Button className="help-btn"   type={"primary"} onClick={(e)=>{
            
           }}>Get In Touch</Button><br/>
            </a></h3>
            </Col>
          </div>
        </Row>
        </div>
 
      </div>
     
   
  )
  }