import React from 'react'
import { Col, Row } from 'antd';
import "../styles/Sage300.css";
import "../styles/Hosting.css";
import "../styles/Testimonial.css";
import RightSection from './RightSection';


const testimonials = [
    {
        name: 'Tom Trently',
        position: 'Vice President of Finance',
        company: 'Advanced Pharmacy',
        quote: 'Due to the efficiency gains using ADSS Global, we were able to reduce our accounting staff from six to three, and our financial statement generation process already has been reduced in half.'
    },
    {
        name: 'Scott Gressak',
        position: 'IT Administrator',
        company: 'Security Engineered Machinery',
        quote: 'After a server crash and losing all data and software for our ERP, CRM and SQL database, ADSS Global was able to help get us back up and running within just a few days...'
    }, {
        name: "Jim Backeland",
        position: "Vice President",
        company: "Icom Canada",
        quote: "Without the technology we have implemented, we would have had to double our staff to maintain our level of service. We have chosen to invest in technology and leverage the talents of our staff. ADSS Global is an invaluable partner in this initiative."
    },
    {
        name: "Patrick Negri",
        position: "Vice President of Finance",
        company: "The Berkely Group, a division of the AON Corporation",
        quote: "ADSS Global has a deep product knowledge and a broad understanding of the insurance industry that has proven to be a real asset to us. We’ve enjoyed great success with the transition of implementing Sage ERP thanks in large part to the team at ADSS Global."
    },
    {
        name: "Howard Aubrey",
        position: "President",
        company: "The Isabella Company",
        quote: "ADSS Global has helped us operate leaner and more efficient. We met with several consultants, but were immediately struck by the knowledge and sincerity of the team at ADSS Global. They are a large enough organization to have resources at their disposal, yet still small enough to give us the personalized attention we value. The deep accounting knowledge and expertise of ADSS Global combined with the efficiencies of Sage ERP helped to make this project a success."
    },
    {
        name: "Dennis Smith",
        position: "Director of Finance",
        company: "Quality Custom Cabinetry, Inc.",
        quote: "We think of ADSS Global as part of our organization. Because they are local, they’re available to us anytime to answer our questions. They know our business and deliver a consistently high level of personalized support."
    },
    {
        name: "Brenda Knight",
        position: "Financial Manager",
        company: "North Carolina Symphony",
        quote: "I am absolutely, positively thrilled with the macro ADSS Global has created!!!!!!!!!!! This could not have come at a better time as just getting back from vacation and no longer having to add up all of these #s – kudos to ADSS Global for a superb job! And, a special thanks for getting those dates to be included so I don’t have to enter those as well. Thank you, thank you, thank you!"
    }, {
        name: "Melanie Fazio",
        position: "Office & Human Resources Manager",
        company: "Keystone Property Group",
        quote: "I just wanted to thank you for permitting KPG to have Keith on the team while we migrated our email. He performed another lifesaving job on Friday and today and I can’t tell you in enough words how grateful I am to have had him assist us with this. Without Keith our ship would have most likely sunk!",
    }, {
        quote: "Thank you so much for moving your IT network engineer’s schedule around for us today on such short notice. And I mean SHORT notice as in an hour and a half. We had three sites, three companies, and our IP phone connectivity that has been horrendous since we moved to our new office in Bala Cynwyd, all banking on this project today. Although your technician Keith was not involved from the start, he was able to come right in and get the job done today. Not just any company or anyone could have done this for us. ADSS Global and Keith really saved us a lot of hardship, time, and money by being able to come in to our site with his superb IT abilities. I can’t stress enough how much it was appreciated.",
        name: "Melanie Fazio",
        position: "Office Manager",
        company: "Keystone Property Group"
    },
    {
        quote: "Just wanted to let you know that Scott and Dan did a great job helping us upgrade the PC’s in our Bala office and setting up our new office in Plymouth Meeting. We had great feedback from everyone as to how smoothly the upgrades went.",
        name: "Mark Komen",
        position: "Network Manager",
        company: "Keystone Property Group"
    },
    {
        quote: "ADSS Global is a great resource for us. They bring a combination of accounting knowledge, software expertise, and IT proficiency that has proven to be a real asset. They always have an eye out for our bottom line!",
        name: "Andy Weinstein",
        position: "Vice President and Controller",
        company: "Health Advocate, Inc."
    },
    {
        quote: "I appreciate the caliber of the staff at ADSS Global — I always get to speak with someone I know, someone qualified, and most important, someone familiar with this business so we’re able to get right to the point without a lot of background explanations.",
        name: "Mike Daley",
        position: "Vice President & Controller",
        company: "RAF Industries"
    },
    {
        name: "Jo Williamson",
        position: "CEO",
        company: "JFK Behavioral Health Center",
        quote: "More than once, ADSS Global has worked with us to resolve imminent network issues much more effectively than we could have by ourselves. Having ADSS Global on our team helps us deliver amazing IT services to our employees and keep them highly productive."
    },
    {
        name: "Jim Kennedy",
        position: "CFO",
        company: "CATCH, Inc.",
        quote: "I really appreciate ADSS Global and Scotts prompt, effective response to getting CATCHs IT system running again. Not only did Scott thoroughly address our problem, he did in a professional, cheerful manner. ADSS Global and Scotts help was invaluable to both me and CATCH."
    }, {
        name: "Beth Nicholas",
        position: "Staff Accountant",
        company: "National Mail Graphics",
        quote: "NMG loves ADSS Global and all of their skills in and knowledge in the Sage ERP System. From a scale of 1 to 10, I would give them a 10 – Fantastic!"
    },
    {
        name: "Cindy Brown",
        position: "CFO",
        company: "Howells Motor Freight, Inc",
        quote: "I truly appreciate the partnership that Howells Motor Freight has with ADSS Global."
    }
];
const CustomerTestimonials = () => {
    return (
        <div>
            <Row className="sage300-page-1 hero-div overlay" role="banner">
                <div className="sage-custom-header">
                    <img
                        src={"https://673dc7.a2cdn1.secureserver.net/wp-content/themes/studio-pro/assets/images/hero.jpg"}
                        alt="Hosting" />
                </div>
                <div>
                    <Col xs={24} sm={12} className="sage-center">
                        <h1>Customer Testimonials</h1>
                    </Col>
                </div>
            </Row>
            <div className="sage-div">
                <div className="sage-left" >
                    <div>
                    {testimonials.map((testimonial, index) => (
                        <div className='testimonial-div' key={index}>
                            <p className='testimonial-text'>"{testimonial.quote}"</p>
                            <p className='testimonial-right'>{testimonial.name} <br />
                                {testimonial.position} <br />
                                {testimonial.company}</p>
                       <hr />
                        </div>
                    ))}
                    </div>
                </div>

                <div className='sage-right'>

                    <img src="https://673dc7.a2cdn1.secureserver.net/wp-content/uploads/Satisfied_Customers_high.jpg?time=1682962927" alt='Sage300' />
                    <RightSection />
                   </div>
            </div>
        </div>
    )
}

export default CustomerTestimonials