
import './App.css';
import 'antd/dist/reset.css';
import { Layout, Menu, Row, Col, Typography, Button, Card } from 'antd';
import { HeadersComponent } from './component/Header';
import { Home } from './component/screens/Home';
import { FooterComponent } from './component/Footer';
import { BrowserRouter, Route, Link, RouterProvider, createBrowserRouter, useNavigate, Routes } from 'react-router-dom';
import { Contact } from './component/screens/Contact';
import { Sage300 } from './component/screens/Sage300';
import { Sage100 } from './component/screens/Sage100';
import { SageIntact } from './component/screens/SageIntact';
import { SageSRM } from './component/screens/SageCRM';
import { SageHRMS } from './component/screens/SageHRMS';
import { Hosting } from './component/screens/Hosting';
import { ManagedServices } from './component/screens/ManagedServices';
import { DisasterRecovery } from './component/screens/DisasterRecovery';
import { ClientCareServices } from './component/screens/ClientCareServices';
import { NetworkSolutions } from './component/screens/NetworkSolutions';
import CustomerSuccesStories from './component/screens/CustomerSuccesStories';
import CustomerTestimonials from './component/screens/CustomerTestimonials';
import PressRelease from './component/screens/PressRelease';
import { About } from './component/screens/About';
import { Office } from './component/screens/Office';
import { AwardAccomplishments } from './component/screens/AwardsAccomplishments';
import { ContactSupport } from './component/screens/ContactSupport';
import { NewsLetter } from './component/screens/NewsLetter';
import { TrainingVideos } from './component/screens/TrainingVideos';

const { Header, Content, Footer } = Layout;

const { Title, Text } = Typography;
const headerStyle = {
  height: '100px',
  lineHeight: '80px',
  width:"100%",
  backgroundColor: '#fff',
  boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.15)',
};

function App() {
 return  <BrowserRouter>
 <Layout>
   <Header style={headerStyle}>
   <HeadersComponent />
   </Header>
   <Routes>
     <Route path="/" element={<Home />} />
     <Route path="/contact" element={<Contact />} />
     <Route path="/software/sage300" element={<Sage300 />} />
     <Route path="/software/sage100" element={<Sage100 />} />
     <Route path="/software/sage-intact" element={<SageIntact />} />
     <Route path="/software/sage-crm" element={<SageSRM />} />
     <Route path="/software/sage-hrms-payroll" element={<SageHRMS />} />
     <Route path="/services/hosting" element={<Hosting />} />
     <Route path="/services/managed-services" element={<ManagedServices />} />
     <Route path="/services/disaster-recovery" element={<DisasterRecovery />} />
     <Route path="/services/client-care" element={<ClientCareServices />} />
     <Route path="/services/network" element={<NetworkSolutions />} />
     <Route path="/company/offices" element={ <Office />} />
     <Route path='company/about-us' element={<About />} />
     <Route path="/company/customer/succes-stories" element={<CustomerSuccesStories />} />
     <Route path="/company/customer/testimonal" element={< CustomerTestimonials/>} />
     <Route path="/company/press-releases" element={<PressRelease/>}/>
     <Route path="/company/awards/" element={<AwardAccomplishments/>}/>
     <Route path="/support/contact-support/" element={<ContactSupport/>}/>
     <Route path="/support/newsletters/" element={<NewsLetter/>}/>
     <Route path="/support/training-videos/" element={<TrainingVideos />}/>



   </Routes>
   <FooterComponent />
 </Layout>
</BrowserRouter>
}

export default App;
