import { Button, Col, Row } from 'antd';
import React from 'react'
import { Link } from 'react-router-dom';
import "../styles/Sage300.css";
import "../styles/About.css";
export const About = () => {
  return (
    <div>
       <Row className="sage300-page-1 hero-div overlay" role="banner">
        <div className="sage-custom-header">
          <img
            src={"https://673dc7.a2cdn1.secureserver.net/wp-content/uploads/2017/12/shaking-hands.jpg"}
            width="1920"
            height="1080"
            alt=""
          />
        </div>
        <div>
            <Col xs={24} sm={12} className="sage-center">
            <h1>ADSS Global</h1>
            <h2>Advanced Dedicated Systems Support with a Global Reach</h2>
            </Col>
          </div>
        </Row>
        
        {/* Middle section */}
           
      <div className='grand-parent-about' >
        <div className='about-parent'>
          <h1>
          One of the Most Experienced Sage Business Partners in the World
          </h1>
          <img src={`${process.env.PUBLIC_URL}/adss_global_logo.jpg`} alt='ADSS Global' />
        </div>
        <div className='about-text-child-one'>
          <p>
          Founded in 1981, ADSS Global is one of the largest, most comprehensive and most experienced <b>Sage Business Partners</b>  in the world with over 80 certified professionals in locations in the East Coast, West Coast, Gulf Coast, Midwest, Pacific Region, Caribbean, and in Canada and Europe. We represent over 3,750 companies in more than 20 different countries. Our staff is multi-lingual speaking and fluent in more than six languages and many of our staff hold certifications as CPA’s, MCSE’s, and CITP’s.
          </p>
          <p>
          As part of our company philosophy, we have established our primary goal as achieving pro-active client service. This commitment is woven through our organization, beginning with training and individual empowerment, self-directed work teams and <i>A</i>dvanced <i>D</i>edicated <i>S</i>ystems <i>S</i>upport with a <i>Global reach</i>.
          </p>
          <h1>ADSS Global is dedicated to the sales, consulting, service and support of the following <b>Sage Software</b> solutions:</h1>
          
          <ul>
            <li> <Link to={'/software/sage300/'} >Sage 300</Link> (formerly Sage Accpac)</li>
            <li> <Link to={'/software/sage100/'} >Sage 100</Link> (formerly Sage MAS 90 / MAS 200)</li>
            <li> <Link to={'/software/sage-intact/'} >Sage Intact</Link></li>
          
            <li> <Link to={'/software/sage-hrms-payroll/'} >Sage HRMS</Link></li>
            <li> <Link to={'/software/sage-crm/'} >Sage CRM</Link> (Customer Relationship Management)</li>
            <li> Sage Fixed Assets</li>
            <li> (U+F10D) Sage Business Intelligence Reporting</li>
            <li> Various Add-on Solutions like Project and Job Costing, POS, and EDI</li>
          </ul>
         <div className='about-child-two-images'>
            <div><img alt='Sage-diamond-partner-badge' src={`${process.env.PUBLIC_URL}/src/Sage-diamond-partner-badge.jpg`} /></div>
            <div><img alt='Sage-cloud-service-badge' src={`${process.env.PUBLIC_URL}/src/Sage-cloud-service-badge.jpg`} /></div>
            <div><img alt='Platinum Club Winner' src={"https://673dc7.a2cdn1.secureserver.net/wp-content/uploads/sage-Platinum_Club_Badge-Color.png"} /></div>
         </div>
          <div className='about-mission-text'>
             <p>
             <img width={50} height={50} className='quote' alt='Sage-cloud-service-badge' src={`${process.env.PUBLIC_URL}/src/quote.svg`} />
             Our mission is to provide Clients with advanced financial software, Customer Relationship Management (CRM) and network infrastructure. This will enable Clients to leverage their investment in technology to enhance long-term equity value and maintain a strong competitive advantage to compete in the Global Economy."</p>
          </div>
          <div className='about-third-child-experience'>
             <p>
               <img width={230} height={230} src={`${process.env.PUBLIC_URL}/src/sage-ceo-2017-winner.png`} alt='sage ceo winner' />
               ADSS Global’s combined experience, proven methodologies and shared national and international resources ensure customer success on every level. Implementing enterprise solutions and providing support through a local presence allows us to be responsive and accessible to our customers.
              </p>
              <p>Our commitment to quality has enabled us to take advantage of the specialized talents and industry experience of each of our locations and maintain the highest quality of customer service.</p>
          </div>
        <div className='about-forth-child-management-applications'>
            <h1>ADSS Global maintains the following On Premise & Cloud Business Management Applications:</h1>
            <div className='list-display'>
             <div className='forth-child-list-one'>
             <ul>
              <li>Financial Accounting and Operations</li>
              <li>Customer Relationship Management (CRM)</li>
              <li>Manufacturing</li>
              <li>Human Resource Management and Payroll</li>
              <li>Warehouse Management</li>
              <li>Retail Management Solutions</li>
              <li>Web-commerce/Web-enabled Solutions</li>
             </ul>
             </div>
             <div className='forth-child-list-two'>
              <ul>
                <li>Electronic Data Interchange (EDI)</li>
                <li>Project and Job Costing</li>
                <li>Field Service</li>
                <li>Enterprise Reporting</li>
                <li>Business Analytics</li>
                <li>Business Process Improvement and Consulting</li>
                <li>Custom Software Development</li>
              </ul>
             </div>
             </div>
        </div>
        </div>
        
        </div>     

        <div className='sage-last-section'>
        <Row className="sage300-page-1 hero-div overlay" role="banner">
        <div className="sage-custom-header">
          <img
            src={"https://673dc7.a2cdn1.secureserver.net/wp-content/uploads/2017/12/shaking-hands.jpg"}
            width="1920"
            height="1080"
            alt=""
          />
        </div>
        <div>
        <Col xs={24} sm={12} className="help-text">
            <h1>How Can We Help?</h1>
            <h3>224 Cairnsmore Circle</h3>
            <h3>Ottawa ON K2J 0G5</h3>
            <h3>613-221-5950</h3>
            <h3> <a href="http://adsshelp.com/"> <em>Or click below to leave a message and one of our consultants will follow up.</em>
           <br/> <Button className="help-btn"   type={"primary"} onClick={(e)=>{
            
           }}>Get In Touch</Button><br/>
            </a></h3>
            </Col>
          </div>
        </Row>
        </div>
      </div>
   
  )
  }