import { Button, Col, Row, Collapse, theme, Divider } from 'antd';
import React from 'react'
import "../styles/Sage300.css";
import "../styles/Newsletter.css";
import { Link } from 'react-router-dom';
import { CaretRightOutlined } from '@ant-design/icons';
import RightSection from './RightSection';
const { Panel } = Collapse;

export const NewsLetter = () => {
  const { token } = theme.useToken();
  const panelStyle = {
    fontSize: 18,
    background: "gary",
    marginBottom: 24,
    borderRadius: token.borderRadiusLG,
    border: '2px  solid silver',
  };
  return (
    <div>
       <Row className="sage300-page-1 hero-div overlay" role="banner">
        <div className="sage-custom-header">
          <img
            src={"https://673dc7.a2cdn1.secureserver.net/wp-content/themes/studio-pro/assets/images/hero.jpg"}
            width="1920"
            height="1080"
            alt=""
          />
        </div>
        <div>
            <Col xs={24} sm={12} className="sage-center">
            <h1>Sage Software Newsletters</h1>
            </Col>
          </div>
        </Row>
        <div className='sage-div'>
          <div className='sage-left'>
           <div className='heading-div-newsletter1'>
            <div className='heading-div'>
           <h1> 2023 Newsletters</h1>
           </div>
           </div>
           <div className='quarters-section'>
            <div className='button-flex'>
             <div className='buttons-div'>
            <Link target={"_blank"} to={"https://673dc7.a2cdn1.secureserver.net/wp-content/uploads/ADSS_300_News_Issue_1_23.pdf"}>  <Button>  1st Quarter </Button></Link></div>
             <div className='buttons-div'>
            <Link target={"_blank"} to={"https://673dc7.a2cdn1.secureserver.net/wp-content/uploads/ADSS_300_News_Issue_2_23.pdf"}>  <Button>2nd Quarter </Button></Link></div>
              </div>
           </div>
           <div className='heading-div-newsletter2'>
            <div className='heading-div' > 
           <h1> 2024 Newsletters</h1>
           </div>
           </div>
           <div className='quarters-section'>
            <div className='button-flex'>
            <div className='buttons-div-2024'>
            <Link target={"_blank"} to={"https://673dc7.a2cdn1.secureserver.net/wp-content/uploads/ADSS_300_News_Issue_1_23.pdf"}>  <Button>  1st Quarter </Button></Link>
            </div>
             <div className='buttons-div-2024'>
            <Link target={"_blank"} to={"https://673dc7.a2cdn1.secureserver.net/wp-content/uploads/ADSS_300_News_Issue_2_23.pdf"}>  <Button>2nd Quarter </Button></Link>
            </div>
            <div className='buttons-div-2024'>
            <Link target={"_blank"} to={"https://673dc7.a2cdn1.secureserver.net/wp-content/uploads/ADSS_300_News_Issue_2_23.pdf"}>  <Button>3rd Quarter </Button></Link>
            </div>
            <div className='buttons-div-2024'>
            <Link target={"_blank"} to={"https://673dc7.a2cdn1.secureserver.net/wp-content/uploads/ADSS_300_News_ye_22.pdf"}>  <Button>Year End </Button></Link>
            </div>
              </div>
              
           </div>

          {/* collops section */}
          <div>
          <Collapse
          
      bordered={false}
      defaultActiveKey={['0']}
      expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
      style={{
        background: token.colorBgContainer,
      }}
    >
      <Panel   header="ARCHIVE (2021 & EARLIER)" key="1" style={panelStyle}>
        <div className='display-block'>
          <h3 className='collapse-heading'>Sage 300 Newsletters <span className='year-color'>(2021)</span></h3>
          <div className='display-flex'>
           <div className='one-one'>
            <Link target={"_blank"} to={"https://673dc7.a2cdn1.secureserver.net/wp-content/uploads/ADSS_300_News_YE21.pdf"} >End Year</Link>
          </div>
          <div className='one-two'>
            <Link  target={"_blank"}  to={"https://673dc7.a2cdn1.secureserver.net/wp-content/uploads/ADSS_300_News_Q3_21.pdf"} >3rd Quarter</Link>
          </div>
          <div className='one-three'>
            <Link  target={"_blank"}  to={"https://673dc7.a2cdn1.secureserver.net/wp-content/uploads/ADSS_300_News_Q2_21.pdf"} >2nd Quarter</Link>
          </div>
          <div className='one-four'>
            <Link  target={"_blank"}  to={"https://673dc7.a2cdn1.secureserver.net/wp-content/uploads/ADSS_300_News_1_21.pdf"} >1st Quarter</Link>
          </div>
          </div>
        </div>
        <Divider style={{ border: '1px solid white', marginBottom:'10%', boxShadow: '1px 1px 2px 0px black',  }} />
        <div className='display-block'>
          <h3 className='collapse-heading'>Sage 300 Newsletters <span className='year-color'>(2020)</span></h3>
          <div className='display-flex'>
           <div className='one-one'>
            <Link target={"_blank"} to={"https://673dc7.a2cdn1.secureserver.net/wp-content/uploads/ADSS-_300_News_YE_20.pdf"} >End Year</Link>
          </div>
          <div className='one-two'>
            <Link target={"_blank"} to={"https://673dc7.a2cdn1.secureserver.net/wp-content/uploads/ADSS_300_News_Issue3_20.pdf"} >3rd Quarter</Link>
          </div>
          <div className='one-three'>
            <Link target={"_blank"} to={"https://673dc7.a2cdn1.secureserver.net/wp-content/uploads/ADSS_300_News_Issue2_20.pdf"} >2nd Quarter</Link>
          </div>
          <div className='one-four'>
            <Link target={"_blank"} to={"https://www.adssglobal.net/wp-content/uploads/ADSS_300_News_Issue1_2020.pdf"}>1st Quarter</Link>
          </div>
          </div>
        </div>
        <Divider style={{ border: '1px solid white', marginBottom:'10%', boxShadow: '1px 1px 2px 0px black',  }} />
        <div className='display-block'>
        <h3 className='collapse-heading'>Sage 300 2019 Newsletters </h3>
          <div className='display-flex'>
           <div className='one-one'>
            <Link target={"_blank"} to={"https://www.adssglobal.net/wp-content/uploads/ADSS_300_News_YE_19.pdf"} >End Year</Link>
          </div>
          <div className='one-two'>
            <Link target={"_blank"} to={"https://www.adssglobal.net/wp-content/uploads/ADSS_300_News_Issue3_19.pdf"} >3rd Quarter</Link>
          </div>
          <div className='one-three'>
            <Link target={"_blank"} to={"https://www.adssglobal.net/wp-content/uploads/ADSS_300_News_Issue2_19.pdf"} >2nd Quarter</Link>
          </div>
          <div className='one-four'>
            <Link target={"_blank"} to={"https://www.adssglobal.net/wp-content/uploads/ADSS_300_News_Issue1_19.pdf"}>1st Quarter</Link>
          </div>
          </div>
        </div>
        <Divider style={{ border: '1px solid white', marginBottom:'10%', boxShadow: '1px 1px 2px 0px black',  }} />
        <div className='display-block'>
          <h3 className='collapse-heading'>Sage 300 2018 Newsletters </h3>
          <div className='display-flex'>
           <div className='one-one'>
            <Link target={"_blank"} to={"https://www.adssglobal.net/wp-content/uploads/ADSS_300_News_YE_18.pdf"} >End Year</Link>
          </div>
          <div className='one-two'>
            <Link target={"_blank"} to={"https://www.adssglobal.net/wp-content/uploads/ADSS_300_News_Issue3_18.pdf"} >3rd Quarter</Link>
          </div>
          <div className='one-three'>
            <Link target={"_blank"} to={"https://www.adssglobal.net/wp-content/uploads/ADSS_300_News_Issue2_18.pdf"} >2nd Quarter</Link>
          </div>
          <div className='one-four'>
            <Link target={"_blank"} to={"https://www.adssglobal.net/wp-content/uploads/ADSS_300_News_Issue1_18.pdf"} >1st Quarter</Link>
          </div>
          </div>
        </div>
        <Divider style={{ border: '1px solid white', marginBottom:'10%', boxShadow: '1px 1px 2px 0px black',  }} />
        <div className='display-block'>
        <h3 className='collapse-heading'>Sage 300 2017 Newsletters </h3>
          <div className='display-flex'>
           <div className='one-one'>
            <Link target={"_blank"} to={"https://www.adssglobal.net/wp-content/uploads/ADSS_300_News_YE_20171.pdf"} >End Year</Link>
          </div>
          <div className='one-two'>
            <Link target={"_blank"} to={"https://www.adssglobal.net/wp-content/uploads/ADSS_300_News_Issue3_171.pdf"}>3rd Quarter</Link>
          </div>
          <div className='one-three'>
            <Link target={"_blank"} to={"https://www.adssglobal.net/wp-content/uploads/ADSS_300_News_Issue_2_171.pdf"}>2nd Quarter</Link>
          </div>
          <div className='one-four'>
            <Link aria-disabled="false">1st Quarter</Link>
          </div>
          </div>
        </div>
        <Divider style={{ border: '1px solid white', marginBottom:'10%', boxShadow: '1px 1px 2px 0px black',  }} />
        <div className='display-block'>
        <h3 className='collapse-heading'>Sage 300 2016 Newsletters </h3>
          <div className='display-flex'>
           <div className='one-one'>
            <Link target={"_blank"} to={"https://www.adssglobal.net/wp-content/uploads/ADSS_300_News_IssueYE_161.pdf"} >End Year</Link>
          </div>
          <div className='one-two'>
            <Link target={"_blank"} to={"https://www.adssglobal.net/wp-content/uploads/ADSS_300_News_Q3_161.pdf"}>3rd Quarter</Link>
          </div>
          <div className='one-three'>
            <Link target={"_blank"} to={"https://www.adssglobal.net/wp-content/uploads/ADSS_300_News_Issue-2-161.pdf"}>2nd Quarter</Link>
          </div>
          <div className='one-four'>
            <Link target={"_blank"} to={"https://www.adssglobal.net/wp-content/uploads/ADSS_300_News_IssueQ1_161.pdf"}>1st Quarter</Link>
          </div>
          </div>
        </div>
        <Divider style={{ border: '1px solid white', marginBottom:'10%', boxShadow: '1px 1px 2px 0px black',  }} />

      </Panel>
      </Collapse>
          </div>
          </div>
          <div className='sage-right'>
          <RightSection />
        </div>
           </div>
          {/* Right Section  */} 
        
        <div className='sage-last-section'>
        <Row className="sage300-page-1 hero-div overlay" role="banner">
        <div className="sage-custom-header">
          <img
            src={"https://673dc7.a2cdn1.secureserver.net/wp-content/themes/studio-pro/assets/images/hero.jpg"}
            width="1920"
            height="1080"
            alt=""
          />
        </div>
        <div>
        <Col xs={24} sm={12} className="help-text">
            <h1>How Can We Help?</h1>
            <h3>224 Cairnsmore Circle</h3>
            <h3>Ottawa ON K2J 0G5</h3>
            <h3>613-221-5950</h3>
            <h3> <a href="http://adsshelp.com/"> <em>Or click below to leave a message and one of our consultants will follow up.</em>
           <br/> <Button className="help-btn"   type={"primary"} onClick={(e)=>{
            
           }}>Get In Touch</Button><br/>
            </a></h3>
            </Col>
          </div>
        </Row>
        </div>
   </div>
  )
  }