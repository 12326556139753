import { Button, Col, Row, Image } from 'antd';
import React from 'react'
import ReactPlayer from 'react-player'
import "../styles/Sage300.css";
import "../styles/sage100.css";
import "../styles/SageIntact.css";
import RightSection from './RightSection';
import { Link } from 'react-router-dom';
export const SageIntact = () => {
  return (
    <div>
       <Row className="sage300-page-1 hero-div overlay" role="banner">
        <div className="sage-custom-header">
          <img
            src={"https://673dc7.a2cdn1.secureserver.net/wp-content/uploads/header-background.jpg"}
            width="1920"
            height="1080"
            alt=""
          />
        </div>
        <div>
            <Col xs={24} sm={12} className="sage-center">
            <h1>SAGE INTACT</h1>
            </Col>
          </div>
        </Row>
        <div className='sage-div'>
          <div className='sage-left'>
          <h1>Cloud-Based Financial Management Software</h1>
          <p>
          Recognized as the #1 cloud accounting software based on customer satisfaction,
           Sage Intacct may just be the first and last financial management software 
           application your business will ever need. Over 11,000 customers worldwide are 
           already streamlining operations, cutting costs, and getting deeper insight into
          their businesses using the powerful features and flexibility of Sage Intacct cloud financials.
          </p>
          <h1>Sage Intacct Features and Modules</h1>
          <p>
          Sage 100 offers a broad selection of features with a low total cost of ownership
           that enables you to choose
           the modules and the platform that best suit your business needs including:
          </p>
           <div>
            <h2>Core Accounting & Finance</h2>
            <p>
            Sage Intacct is built around a multi-dimensional general ledger that works together with the other 
            “core financial” modules like Accounts Payable and Receivable, Order Management and Purchasing, 
            Reporting and Dashboards, Cash Management, Spend Management, and so much more.
            </p>
            <h2>Extended Modules</h2>
            <p>
            If your business requires added functionality like Project Management, Contract and Subscription 
            Billing, Multi-Entity and Global Consolidations, Fixed Assets, and Inventory Management, 
            Sage Intacct has you covered with additional modules that work seamlessly with the core 
            financials.
            </p>
           </div>
           <h2>Connected Ecosystem</h2>
           <p>
           Discover just how easy it is to connect Sage Intacct with other popular business 
           systems you may already be using today, or integrate with new applications you may 
           be planning to use down the road as you grow.
           </p>
           <h2>Preferred by the AICPA</h2>
           <p>Sage Intacct is the choice of the experts in accounting. In fact, the AICPA 
            acknowledged Intacct as the preferred provider of financial applications after a
             thorough review of software providers.
             
             <div>
              <h1>Sage Intacct by Industry</h1>
              <Image className='ZoomImg' preview="false" src='https://673dc7.a2cdn1.secureserver.net/wp-content/uploads/intacct-industries.png' />
             </div>
             <p>The cloud-based technology and wide range of features are a great fit for all types of companies including some of the following that Sage Intacct has a significant presence in today:</p>
             <div>

            <ul>
            <li>Accounting Firms and Financial Services</li>
            <li>Franchise Operations</li>
            <li>Healthcare</li>
            <li>Hospitality</li>
            <li>Nonprofit Organizations</li>
            <li>Professional Services</li>
            <li>Software and SaaS</li>
            <li>Wholesale Distribution</li>
            </ul>
          </div>
             </p>
             
           <div>
            <Button className='demo-btn' type='primary'><Link>REQUEST A DEMO »</Link></Button>
           </div>
           <hr />
           <div className='sage-left-video'>
           <ReactPlayer className='react-player' url={"https://www.youtube.com/watch?v=9NHjZTGEZ9w"} controls={true}  />
           </div>
          </div>
          {/* Right Section  */}
          <div className='sage-right'>
          <img src="https://673dc7.a2cdn1.secureserver.net/wp-content/uploads/intacct-screenshot.png?time=1682180858"  alt='Sage300'/>
          <div>
            <RightSection />
          </div>
          </div>
        </div>
        <div className='sage-last-section'>
        <Row className="sage300-page-1 hero-div overlay" role="banner">
        <div className="sage-custom-header">
          <img
            src={"https://673dc7.a2cdn1.secureserver.net/wp-content/uploads/header-background.jpg"}
            width="1920"
            height="1080"
            alt=""
          />
        </div>
        <div>
        <Col xs={24} sm={12} className="help-text">
            <h1>How Can We Help?</h1>
            <h3>224 Cairnsmore Circle</h3>
            <h3>Ottawa ON K2J 0G5</h3>
            <h3>613-221-5950</h3>
            <h3> <a href="http://adsshelp.com/"> <em>Or click below to leave a message and one of our consultants will follow up.</em>
           <br/> <Button className="help-btn"   type={"primary"} onClick={(e)=>{
            
           }}>Get In Touch</Button><br/>
            </a></h3>
            </Col>
          </div>
        </Row>
        </div>
      </div>
   
  )
  }